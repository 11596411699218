import { toast } from 'react-toastify';

export default async function callApi(method: string, url: string, path: string, token: string, data?: any) {
    const res = await fetch(url + path, {
        method,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            auth: token,
        },
        body: JSON.stringify(data),
    });
    if (res.status !== 200 && res.status !== 401) {
        console.warn(res);
    } else if (res.status === 401) {
        // toast("Your session expired, please login again", { type: toast.TYPE.ERROR });
        localStorage.clear();
        window.location.href = '/login';
    }
    if (res.headers.has('token')) {
        localStorage.setItem('token', res.headers.get('token')!);
    }
    // console.log(res.body);
    return await res.json();
}
