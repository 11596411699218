import { ColorPair } from "./Post";

export enum SentenceFrequency {
    ONCE = 'ONCE',
    TWICE = 'TWICE',
    FIXED = 'FIXED',
    ROUND_1 = 'ROUND_1',
    ROUND_2 = 'ROUND_2',
    ROUND_3 = 'ROUND_3',
}

export interface TitleSentence {
    id: string;
    createdBy?: string;
    sentence: {
        he?: string;
        en: string;
    };
    second: {
        he?: string;
        en: string;
    };
    titleColor?: ColorPair & string;
    secondColor?: ColorPair & string;
    frequency: SentenceFrequency;
    isEnabled: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export class FilteredSentences {
    once: TitleSentence[] = [];
    twice: TitleSentence[] = [];
    fixed: TitleSentence[] = [];
    round1: TitleSentence[] = [];
    round2: TitleSentence[] = [];
    round3: TitleSentence[] = [];

    constructor(sentences: TitleSentence[]) {
        sentences.forEach(cur => {
            switch (cur.frequency) {
                case SentenceFrequency.FIXED:
                    this.fixed.push(cur);
                    break;
                case SentenceFrequency.ONCE:
                    this.once.push(cur);
                    break;
                case SentenceFrequency.TWICE:
                    this.twice.push(cur);
                    break;
                case SentenceFrequency.ROUND_1:
                    this.round1.push(cur);
                    break;
                case SentenceFrequency.ROUND_2:
                    this.round2.push(cur);
                    break;
                case SentenceFrequency.ROUND_3:
                    this.round3.push(cur);
                    break;

                default:
                    break;
            }
        });
    }
}

export interface SentenceUsages {
    once: number;
    twice: number;
    onceExcluded: string[];
    twiceExcluded: string[];
}