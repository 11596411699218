import { Store, createStore, applyMiddleware, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { persistStore, persistReducer, Persistor } from 'redux-persist';
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
// import { connectRouter, routerMiddleware } from 'connected-react-router'
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension';
// If you use react-router, don't forget to pass in your history type.
// import storage from 'redux-persist/lib/storage';
import { ApplicationState, rootReducer, rootSaga } from './store';

// const persistConfig = {
//     key: 'root',
//     storage,
//     blacklist: ['post', 'user', 'login', 'store', 'storeTypes'], // navigation will not be persisted
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(
    initialState: ApplicationState,
): { store: Store<ApplicationState & AnyAction> & { dispatch: unknown } } {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});
    // create the redux-saga middleware
    const sagaMiddleware = createSagaMiddleware();

    // We'll create our store with the combined reducers/sagas, and the initial Redux state that
    // we'll be passing from our entry point.
    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware))) as any;
    // const persistor = persistStore(store);
    // Don't forget to run the root saga, and return the store object.
    sagaMiddleware.run(rootSaga);
    return { store };
}
