import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { StoreActionTypes } from './types';
import callApi from '../../utils/api';
import { ApplicationState } from '..';

import { toast } from 'react-toastify';
import { ICategory } from '../../types/Category';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { IStoreType, IStore } from '../../types/Store';
import i18next from 'i18next';
import {
    fetchedStoreTypes,
    fetchError,
    fetchStores,
    fetchedStores,
    fetchStore,
    fetchedStore,
    fetchedUserStore,
    saveStore,
    publishStore,
    removeStore,
    updateStore,
    fetchUserStore,
    fetchUserStores,
    fetchedUserStores,
} from './actions';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getCategories = (state: ApplicationState) => state.post.categories;
export const getUser = (state: ApplicationState) => state.user.user;

function* handleFetchStoreTypes() : any{
    try {
        const storeTypesRes: IStoreType[] = yield call(callApi, 'get', API_ENDPOINT, '/store/types/', '');

        console.log('fetched store types', storeTypesRes);

        yield put(fetchedStoreTypes(storeTypesRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchStores(action: ReturnType<typeof fetchStores>): any {
    // let url = `/post/list${action.payload.searchRequest.onlyFavorites ? 'favorites' : ''}/`;
    // let token = action.payload.searchRequest.onlyFavorites ? yield select(getToken) :'';

    // if (action.payload.page && action.payload.countPerPage) url += `${action.payload.page}/${action.payload.countPerPage}/`;
    // console.log(url);
    try {
        const stores: IStore[] = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/store/list/${action.payload ? action.payload : ''}`,
            '',
        );

        // const categories: ICategory[] = yield select(getCategories);
        // // console.log('cassdtttt', categories);
        // if (categories && categories.length > 0)
        // filteredPosts.paginatedResults.map(post=> {
        //     if (post.cat_main) post.category1 = categories.find(cat=>cat.id===post.cat_main) || {} as ICategory;
        //     if (post.category1 && post.category1.subCategories && post.sub_cat) post.category2 = post.category1.subCategories.find(cat=>cat.id===post.sub_cat) || {} as ICategory;
        //     if (post.category2 && post.category2.subCategories && post.sub_cat_section) post.category3 = post.category2.subCategories.find(cat=>cat.id===post.sub_cat_section) || {} as ICategory;
        //     return post;
        // })
        // console.log("fetched posts!!", filteredPosts);

        yield put(fetchedStores(stores));
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchStore(action: ReturnType<typeof fetchStore>): any {
    try {
        const storeRes: ApiResponse & IStore = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/store/${action.payload.brand_url}`,
            '',
            action.payload.search,
        );

        console.log('fetched post', storeRes);
        const categories: ICategory[] = yield select(getCategories);
        // console.log('cassdtttt(storecat)', categories);
        if (categories && categories.length > 0)
            if (storeRes.error) {
                // if (storeRes.cat_main) storeRes.category1 = categories.find(cat=>cat.id===storeRes.cat_main) || {} as ICategory;
                // if (storeRes.category1 && storeRes.category1.subCategories && storeRes.sub_cat) storeRes.category2 = storeRes.category1.subCategories.find(cat=>cat.id===storeRes.sub_cat) || {} as ICategory;
                // if (storeRes.category2 && storeRes.category2.subCategories && storeRes.sub_cat_section) storeRes.category3 = storeRes.category2.subCategories.find(cat=>cat.id===storeRes.sub_cat_section) || {} as ICategory;

                yield put(fetchError(storeRes.error));
            } else {
                yield put(fetchedStore(storeRes));
            }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUserStore(action: ReturnType<typeof fetchUserStore>) : any{
    const token = yield select(getToken);
    try {
        const storesRes: ApiResponse & IStore = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/store/user/${action.payload}`,
            token,
        );
        if (storesRes.error) {
            yield put(fetchError(storesRes.error));
        } else {
            yield put(fetchedUserStore(storesRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUserStores() : any{
    const token = yield select(getToken);
    try {
        const storesRes: ApiResponse & IStore[] = yield call(callApi, 'get', API_ENDPOINT, `/store/my/`, token);
        if (storesRes.error) {
            yield put(fetchError(storesRes.error));
        } else {
            yield put(fetchedUserStores(storesRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleSaveStore(action: ReturnType<typeof saveStore>) : any{
    try {
        const token = yield select(getToken);

        // To call async functions, use redux-saga's `call()`.
        console.log('saving', token, action.payload);
        const res: any = yield call(callApi, 'post', API_ENDPOINT, `/store/add/`, token, action.payload);

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            toast(t('Store saved but not published yet'), { type: toast.TYPE.SUCCESS });
            const curStore = action.payload;
            curStore.id = res.result;
            console.log(curStore, res);
            yield put(fetchedUserStore(curStore));
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handlePublishStore(action: ReturnType<typeof publishStore>) : any{
    try {
        const token = yield select(getToken);
        console.log(token);
        // To call async functions, use redux-saga's `call()`.
        const res: any = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/store/publish/${action.payload.id}`,
            token,
            action.payload,
        );

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            toast(t('Membership changed'), { type: toast.TYPE.SUCCESS });
            const curStore = action.payload;
            curStore.id = res.result;
            curStore.is_payed = true;
            curStore.is_active = true;

            yield put(fetchedStore(Object.assign({}, curStore)));
            yield put(fetchUserStores());
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveStore(action: ReturnType<typeof removeStore>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/store/${action.payload}`,
            token,
        );

        // console.log(res);
        const t = i18next.t.bind(i18next);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error removing store'));
            toast(t('Error removing store'), { type: toast.TYPE.ERROR });
        } else {
            toast(t('Store removed'), { type: toast.TYPE.SUCCESS });
            yield put(fetchUserStores());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateStore(action: ReturnType<typeof updateStore>) : any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: ApiResponse & IStore = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/store/${action.payload.id || action.payload._id}`,
            token,
            action.payload,
        );

        // console.log(res);
        const t = i18next.t.bind(i18next);
        if (res.error) {
            console.log('Error updating store', res);
            yield put(fetchError('Error updating store'));
            toast(t('Error updating store'), { type: toast.TYPE.ERROR });
        } else {
            toast(t('Store data updated'), { type: toast.TYPE.SUCCESS });
            yield put(fetchedStore(res));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* watchFetchStoreTypesRequest() {
    yield takeEvery(StoreActionTypes.FETCH_STORETYPES, handleFetchStoreTypes);
}
function* watchFetchStoresRequest() {
    yield takeEvery(StoreActionTypes.FETCH_STORES, handleFetchStores);
}
// function* watchFetchFavoritesRequest() { yield takeEvery(StoreActionTypes.FETCH_FAVORITES, handleFetchFavorites) }
function* watchFetchStoreRequest() {
    yield takeEvery(StoreActionTypes.FETCH_STORE, handleFetchStore);
}
function* watchFetchUserStoreRequest() {
    yield takeEvery(StoreActionTypes.FETCH_USERSTORE, handleFetchUserStore);
}
function* watchFetchUserStoresRequest() {
    yield takeEvery(StoreActionTypes.FETCH_USERSTORES, handleFetchUserStores);
}
function* watchSaveStoreRequest() {
    yield takeEvery(StoreActionTypes.SAVE_STORE, handleSaveStore);
}
function* watchUpdateStoreRequest() {
    yield takeEvery(StoreActionTypes.UPDATE_STORE, handleUpdateStore);
}
function* watchPublishStoreRequest() {
    yield takeEvery(StoreActionTypes.PUBLISH_STORE, handlePublishStore);
}
function* watchRemoveStoreRequest() {
    yield takeEvery(StoreActionTypes.REMOVE_STORE, handleRemoveStore);
}
// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* storeSaga() {
    yield all([
        fork(watchFetchStoreTypesRequest),
        fork(watchFetchStoresRequest),
        fork(watchFetchStoreRequest),
        fork(watchFetchUserStoreRequest),
        fork(watchSaveStoreRequest),
        fork(watchUpdateStoreRequest),
        fork(watchPublishStoreRequest),
        fork(watchRemoveStoreRequest),
        fork(watchFetchUserStoresRequest),
    ]);
}
