import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import Routes from './Routes';
import { BrowserRouter, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { PersistGate } from 'redux-persist/integration/react';

import configureStore from './configureStore';
// import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
// import * as bcrypt from 'bcryptjs';

import i18n from './i18n';

import ReactGA from 'react-ga';
import { Loader } from './components/Loader';
// import 'moment/src/locale/he';
import moment from 'moment';
import 'moment/locale/he';
// import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { hotjar } from 'react-hotjar';
import i18next from 'i18next';
if (
    process.env.REACT_APP_HOTJAR_ID &&
    process.env.REACT_APP_HOTJAR_ID.length > 0 &&
    process.env.REACT_APP_HOTJAR_SNIPPET_VER
)
    hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID), parseInt(process.env.REACT_APP_HOTJAR_SNIPPET_VER));
if (process.env.REACT_APP_GOOGLE_ANALYTICS) ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const initialState = window.initialReduxState;
const { store } = configureStore(initialState);

// Any additional component props go here.
interface MainProps {
    // store: Store<ApplicationState>
}
interface IState {
    password: string;
    initialized: boolean;
    isDisconnected: boolean;
    offlineToastId?: string | number;
}

class Main extends React.Component<MainProps, IState> {
    public constructor(props: MainProps) {
        super(props);
        this.state = {
            password: '',
            initialized: false,
            isDisconnected: false,
        };
    }
    public onSWUpdate = (registration: ServiceWorkerRegistration) => {
        // setShowReload(true);
        // setWaitingWorker(registration.waiting);
        // console.log('A new version is available!', registration.waiting);
        // if (registration.waiting)
        //     toast.info(i18n.t('A new version is available! Click to update'), {
        //         position: 'bottom-center',
        //         autoClose: false,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: false,
        //         onClose: () => {
        //             registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
        //             // console.log('skipping', registration.waiting);
        //             // setShowReload(false);
        //             window.location.reload(true);
        //         },
        //     });
    };

    public componentDidMount() {
        serviceWorkerRegistration.register({ onUpdate: this.onSWUpdate });
        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();

        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        i18n.on('languageChanged', () => {
            moment.locale(i18n.language);
            this.setState({});
        });
        i18n.on('initialized', () => {
            this.setState({ initialized: true });
            moment.locale(i18n.language);
        });
    }
    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        if (condition === 'online') {
            if (this.state.isDisconnected) {
                console.log('offline', this.state.offlineToastId);
                if (this.state.offlineToastId) toast.dismiss(this.state.offlineToastId);
                toast.success(i18n.t('Online connection restored. All functionality enabled'), {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                });
            }

            this.setState({ isDisconnected: false, offlineToastId: undefined });
            return;
        }
        let offlineToastId = this.state.offlineToastId;
        if (!offlineToastId) {
            offlineToastId = toast.info(i18n.t('You are offline. Some functionality is disabled'), {
                position: 'bottom-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
            });
        }
        return this.setState({ isDisconnected: true, offlineToastId });
    };
    public render() {
        if (!this.state.initialized) return <Loader />;
        return (
            <Provider store={store}>
                <div>
                    <ToastContainer position={i18next.dir() === 'rtl' ? 'top-left' : 'top-right'} />
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </div>
            </Provider>
        );
    }
}

export default Main;
