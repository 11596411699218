import { action } from 'typesafe-actions';
import { StoreActionTypes } from './types';
import { IStore, IStoreType } from '../../types/Store';
import { ISearchPostRequest } from '../../types/Post';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const fetchStoreTypes = () => action(StoreActionTypes.FETCH_STORETYPES);
export const fetchStore = (brand_url: string, search?: ISearchPostRequest) => action(StoreActionTypes.FETCH_STORE, {brand_url, search});
export const fetchUserStore = (id: string) => action(StoreActionTypes.FETCH_USERSTORE, id);
export const fetchUserStores = () => action(StoreActionTypes.FETCH_USERSTORES);

export const removeStore = (id: string) => action(StoreActionTypes.REMOVE_STORE, id);
// export const fetchFavorites = (searchRequest: ISearchPostRequest, page?: number, countPerPage?: number) => action(StoreActionTypes.FETCH_FAVORITES, {searchRequest, page, countPerPage})
export const fetchStores = (type_id?: string) => action(StoreActionTypes.FETCH_STORES, type_id);
export const saveStore = (store: IStore) => action(StoreActionTypes.SAVE_STORE, store);
export const updateStore = (store: IStore) => action(StoreActionTypes.UPDATE_STORE, store);
export const publishStore = (store: IStore) => action(StoreActionTypes.PUBLISH_STORE, store);

export const fetchedStoreTypes = (storeTypes: IStoreType[]) => action(StoreActionTypes.FETCHED_STORETYPES, storeTypes);

export const fetchedStore = (store: IStore) => action(StoreActionTypes.FETCHED_STORE, store);
export const fetchedStores = (stores: IStore[]) => action(StoreActionTypes.FETCHED_STORES, stores);
export const fetchedUserStore = (userStore: IStore) => action(StoreActionTypes.FETCHED_USERSTORE, userStore);
export const fetchedUserStores = (userStores: IStore[]) => action(StoreActionTypes.FETCHED_USERSTORES, userStores);
export const fetchSuccess = (store: IStore) =>
    action(StoreActionTypes.FETCH_SUCCESS, {
        store,
    });
export const fetchError = (message: string) => action(StoreActionTypes.FETCH_ERROR, message);
