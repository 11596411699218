import 'ts-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import 'jquery/src/jquery';

// import './assets/js/jquery-1.11.2.min.js';
// import './assets/js/jquery-ui.min.js';
// import './assets/js/webslidemenu.js';
// import 'jquery/src/jquery';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/main.scss';
import './assets/css/responsive.css';
import './index.css';

// import './i18n';

// import App from './App';
import Main from './Main';
// import * as serviceWorker from './serviceWorker';

// function noop() {}

// if (process.env.REACT_APP_SKIP_CONSOLE_LOG === '1') {
//     console.log = noop;
//     console.warn = noop;
//     console.error = noop;
// }

ReactDOM.render(<Main />, document.getElementById('root'));

// serviceWorker.register();
