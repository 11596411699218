import { IStore, IStoreType } from '../../types/Store';

export enum StoreActionTypes {
    FETCH_STORES = '@@store/FETCH_STORES',
    FETCH_USERSTORE = '@@store/FETCH_USERSTORE',
    FETCH_USERSTORES = '@@store/FETCH_USERSTORES',
    FETCH_STORE = '@@store/FETCH_STORE',
    REMOVE_STORE = '@@store/REMOVE_STORE',
    SAVE_STORE = '@@store/SAVE_STORE',
    PUBLISH_STORE = '@@store/PUBLISH_STORE',
    UPDATE_STORE = '@@store/UPDATE_STORE',

    UPDATED_STORE = '@@store/UPDATED_STORE',
    FETCHED_STORE = '@@store/FETCHED_STORE',
    FETCHED_STORES = '@@store/FETCHED_STORES',
    FETCHED_USERSTORE = '@@store/FETCHED_USERSTORE',
    FETCHED_USERSTORES = '@@store/FETCHED_USERSTORES',

    FETCH_SUCCESS = '@@store/FETCH_SUCCESS',
    FETCH_ERROR = '@@store/FETCH_ERROR',

    FETCH_STORETYPES = '@@store/FETCH_STORETYPES',
    FETCHED_STORETYPES = '@@store/FETCHED_STORETYPES',
}

export interface StoreState {
    readonly storeTypes: IStoreType[];
    readonly stores: IStore[];
    readonly userStores: IStore[];
    readonly userStore: IStore;
    readonly currentStore: IStore;
    readonly loading: boolean;
    readonly errors?: string;
}
