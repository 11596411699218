import { all, call, fork, put, takeEvery, select, take } from 'redux-saga/effects';
import { UserActionTypes } from './types';
import {
    fetchError,
    fetchSuccess,
    updateUser,
    fetchUser,
    uploadAvatar,
    editFavorites,
    loginError,
    fetchTransactions,
    balanceChange,
    fetchRates,
    ratesFetched,
    fetchBids,
    chargeOrder,
    fetchOrder,
    refundOrder,
    fetchedOrder,
    sendMessage,
    fetchMessages,
    listenNewMessagesSocket,
    listenNewMessagesSocketReceived,
    createdApi,
    markMessagesViewed,
    setRate,
    leaveFeedback,
    editFeedback,
    fetchSeller,
    fetchedSeller,
    removeMessages,
    requestOtp,
    onFetchedEmailTemplates,
    validatePromo,
    validatedPromo,
    sentencesFetched,
} from './actions';
import callApi from '../../utils/api';
import i18next from 'i18next';
import { ApplicationState } from '..';

import { toast } from 'react-toastify';
import { IUser, RegisterPromoCode } from '../../types/User';
import callApiUpload from '../../utils/callApi';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { applyRate, BALANCE_CHANGE_TYPES } from '../../constants';
import { ICurrency } from '../../types/Currency';
import { IBid } from '../../types/Post';
import { IOrder } from '../../types/Order';
import { Message } from '../../types/Message';
import { createListenMessagesChannel } from './channel';
import { IEmailTemplate } from '../../types/EmailTemplate';
import { getCategories } from '../post/sagas';
import { ICategory } from '../../types/Category';
import { TitleSentence } from '../../types/TitleSentence';
// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getUser = (state: ApplicationState) => state.user.user;
export const getRates = (state: ApplicationState) => state.user.rates;
export const getSentences = (state: ApplicationState) => state.user.titleSentences;
export const getCurRate = (state: ApplicationState) => state.user.curRate;

function* handleValidatePromo(action: ReturnType<typeof validatePromo>): any {
    try {
        const promoRes: ApiError & RegisterPromoCode = yield call(callApi, 'get', API_ENDPOINT, `/user/registerpromo/${action.payload}`, '');

        console.log('validated promoRes', promoRes);

        // postRes._id = postRes.id;
        if (promoRes.error) {
            console.log('Error validating promoRes', promoRes.error);
            yield put(fetchError(promoRes.error));
        } else {
            yield put(validatedPromo(promoRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUser(): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/user/tokenlogin`, '', action.payload)
        const token = yield select(getToken);
        // console.log(token);
        if (!token || token === 'undefined' || token === '') return yield put(fetchError('Token not in state.'));

        const userRes: IUser & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/user/me', token);
        console.log('fetched user', userRes);

        if (userRes.error) {
            yield put(loginError());
            const t = i18next.t.bind(i18next);
            toast(t(userRes.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(userRes.error));
        } else {
            yield put(listenNewMessagesSocket(token, userRes));
            // yield put(fetchBids());
            // const rates = yield select(getRates);
            // yield put(setRate(userRes.currency, rates));
            // toast("Bid accepted", { type: toast.TYPE.SUCCESS });
            yield put(fetchSuccess(userRes));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchSeller(action: ReturnType<typeof fetchSeller>) :any {
    try {
        const sellerRes: IUser & ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            '/user/seller/' + action.payload,
            '',
        );
        console.log('fetched seller', sellerRes);

        if (sellerRes.error) {
            yield put(loginError());
            const t = i18next.t.bind(i18next);
            toast(t(sellerRes.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(sellerRes.error));
        } else {
            // yield put(listenNewMessagesSocket(token, userRes));
            // yield put(fetchBids());
            // const rates = yield select(getRates);
            // yield put(setRate(userRes.currency, rates));
            // toast("Bid accepted", { type: toast.TYPE.SUCCESS });
            yield put(fetchedSeller(sellerRes));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchRates() :any{
    // console.log('fetch rates');
    try {
        const rates = yield select(getRates);
        if (Object.keys(rates).length > 0) {
            yield put(ratesFetched(rates));
            return;
        }

        const res: ApiError & ICurrency = yield call(callApi, 'get', API_ENDPOINT, `/user/rates`, '');
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            yield put(ratesFetched(res));
            const user: IUser = yield select(getUser);
            if (user && user.currency) yield put(setRate(user.currency, res));
        }
    } catch (err) {
        console.log('err', err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchSentences() :any{
    // console.log('fetch rates');
    try {
        const sentences = yield select(getSentences);
        if (sentences && sentences.length > 0) {
            yield put(sentencesFetched(sentences));
            return;
        }

        const res: ApiError & TitleSentence[] = yield call(callApi, 'get', API_ENDPOINT, `/setting/sentence`, '');
        if (res.error) {
            // const t = i18next.t.bind(i18next);
            // toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            yield put(sentencesFetched(res));
            // const user: IUser = yield select(getUser);
            // if (user && user.currency) yield put(setRate(user.currency, res));
        }
    } catch (err) {
        console.log('err', err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchTransactions() :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const curUser: IUser = yield select(getUser);
        if (!curUser.username) return yield put(fetchError('User not in state.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/user/balance`, token);

        // console.log('TRANSACTIONS', curUser, res);
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res));
        } else {
            curUser.transactions = res;
            // toast("User data updated", { type: toast.TYPE.SUCCESS });
            yield put(fetchSuccess(curUser));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleCreateApi():any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: { apiKey: string; apiKeyCreateDate: Date } & ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/api/new`,
            token,
        );

        // console.log('TRANSACTIONS', curUser, res);
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            // toast("User data updated", { type: toast.TYPE.SUCCESS });
            yield put(createdApi(res.apiKey, res.apiKeyCreateDate));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRevokeApi() :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: { result: boolean } & ApiError = yield call(callApi, 'get', API_ENDPOINT, `/user/api/revoke`, token);

        // console.log('TRANSACTIONS', curUser, res);
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            // toast("User data updated", { type: toast.TYPE.SUCCESS });
            yield put(fetchUser());
            // yield put(createdApi(undefined, undefined));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleListenMessages(action: ReturnType<typeof listenNewMessagesSocket>) :any{
    const token = action.payload.token;
    const curUser = action.payload.user;

    const channel = yield call(createListenMessagesChannel, curUser.id, token);
    console.log('INIT_SOCKET_listenmessages', curUser.id, token);
    if (!channel) {
        console.log('EXIST_listenmessages');
        return;
    }
    while (true) {
        const newMessage: Message = yield take(channel);
        // let postRes: IPost = JSON.parse(updatedPostData);
        console.log('SOCKET_listenmessages', newMessage);

        yield put(listenNewMessagesSocketReceived(newMessage));
    }
}

function* handleFetchMessages() :any{
    // console.log('fetching messages');
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const curUser: IUser = yield select(getUser);
        if (!token || !curUser.id) return yield put(fetchError('User not in states.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: Message[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, `/user/messages`, token);

        // console.log('fetched messages', res);
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            curUser.messages = res;
            // toast('User data updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchSuccess(curUser));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleMarkMessagesViewed(action: ReturnType<typeof markMessagesViewed>) :any{
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const messageIDs = action.payload.map((msg) => msg._id);
        const res: ApiError = yield call(callApi, 'post', API_ENDPOINT, `/user/markMessagesViewed/`, token, {
            messageIDs,
        });

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            // toast(`Message has been sent`, { type: 'success' });

            yield put(fetchMessages());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemoveMessage(action: ReturnType<typeof removeMessages>) :any{
    try {
        console.log('removing');
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const messageIDs = action.payload.map((msg) => msg._id);
        const res: ApiError = yield call(callApi, 'post', API_ENDPOINT, `/user/messages/remove`, token, {
            messageIDs,
        });
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Message has been deleted`), { type: 'success' });

            yield put(fetchMessages());
            // window.location.href = '/dashboard/messages';
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleLeaveFeedback(action: ReturnType<typeof leaveFeedback>):any{
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // const messageObject = {
        //     receiverId: action.payload.receiver.id,
        //     type: "message",
        //     emailMessage: action.payload.emailMessage,
        //     message: action.payload.message
        // }
        // console.log(messageObject);
        const res: ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/user/order/feedback/${action.payload.bid._id}`,
            token,
            action.payload.review,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Feedback has been left`), { type: 'success' });

            yield put(fetchOrder(action.payload.bid));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleEditFeedback(action: ReturnType<typeof editFeedback>) :any{
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // const messageObject = {
        //     receiverId: action.payload.receiver.id,
        //     type: "message",
        //     emailMessage: action.payload.emailMessage,
        //     message: action.payload.message
        // }
        // console.log(messageObject);
        const res: ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/user/order/feedback/${action.payload.bid._id}`,
            token,
            action.payload.review,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Feedback successfully edited`), { type: 'success' });

            yield put(fetchOrder(action.payload.bid));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleSendMessage(action: ReturnType<typeof sendMessage>) :any{
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // const messageObject = {
        //     receiverId: action.payload.receiver.id,
        //     type: "message",
        //     emailMessage: action.payload.emailMessage,
        //     message: action.payload.message
        // }
        // console.log(messageObject);
        const res: ApiError = yield call(callApi, 'post', API_ENDPOINT, `/user/messages/send/`, token, action.payload);
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Message has been sent`), { type: 'success' });

            yield put(fetchMessages());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchBids() :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const curUser: IUser = yield select(getUser);
        if (!token || !curUser.id) return yield put(fetchError('User not in states.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: IBid[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, `/user/bids`, token);

        // console.log(res);
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            curUser.bids = res.filter(cur=> cur.bidder_id === curUser.id);
            // toast("User data updated", { type: toast.TYPE.SUCCESS });
            yield put(fetchSuccess(curUser));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRequestOtp(action: ReturnType<typeof requestOtp>) :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        // const curUser: IUser = yield select(getUser);
        // if (!curUser) return yield put(fetchError('User not in state.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: any = yield call(callApi, 'get', API_ENDPOINT, `/user/requestotp`, token);

        // console.log(res)
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t('Error'), { type: toast.TYPE.ERROR });
            console.log('res.error', res.error);
            yield put(fetchError(res));
        } else {
            toast(t('Please check your email for one-time code'));
            // try {
            //     if (action.payload.type === BALANCE_CHANGE_TYPES[0]) {
            //         toast(
            //             `$${action.payload.amount.toFixed(2)} ${t('filled to your account from')} ${
            //                 action.payload.source
            //             }`,
            //         );
            //         curUser.balance += action.payload.amount;
            //     }
            //     if (action.payload.type === BALANCE_CHANGE_TYPES[1]) {
            //         toast(`$${action.payload.amount.toFixed(2)} ${t('withdrawed from your account')}`);
            //         curUser.balance -= action.payload.amount;
            //         yield put(fetchTransactions());
            //     }
            //     yield put(fetchSuccess(curUser));
            // } catch (error) {
            //     console.log('error', error);
            // }

            // if (!curUser.transactions) curUser.transactions = [];
            // curUser.transactions.push(res);
            // toast("User data updated", { type: toast.TYPE.SUCCESS });

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleBalanceChange(action: ReturnType<typeof balanceChange>) :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const curUser: IUser = yield select(getUser);
        if (!curUser) return yield put(fetchError('User not in state.'));
        const curRate: string = yield select(getCurRate);
        if (!curRate) return yield put(fetchError('User not in state.'));
        const rates: ICurrency = yield select(getRates);
        if (!rates) return yield put(fetchError('Rates not in state.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: any = yield call(callApi, 'post', API_ENDPOINT, `/user/balance`, token, action.payload);

        // console.log(res)
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t('Error'), { type: toast.TYPE.ERROR });
            console.log('res.error', res.error);
            yield put(fetchError(res));
        } else {
            try {
                if (action.payload.type === BALANCE_CHANGE_TYPES[0]) {
                    toast(
                        `${applyRate(rates, 'USD', curRate, action.payload.amount)} ${t(
                            'filled to your account from',
                        )} ${action.payload.source}`,
                    );
                    curUser.balance += action.payload.amount;
                }
                if (action.payload.type === BALANCE_CHANGE_TYPES[1]) {
                    toast(
                        `${applyRate(rates, 'USD', curRate, action.payload.amount)} ${t(
                            'withdrawed from your account',
                        )}`,
                    );
                    curUser.balance -= action.payload.amount;
                    yield put(fetchTransactions());
                }
                yield put(fetchSuccess(curUser));
            } catch (error) {
                console.log('error', error);
            }

            // if (!curUser.transactions) curUser.transactions = [];
            // curUser.transactions.push(res);
            // toast("User data updated", { type: toast.TYPE.SUCCESS });

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchOrder(action: ReturnType<typeof fetchOrder>):any {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: IOrder & ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/order/view/${action.payload._id}`,
            token,
        );
        console.log('FETCH ORDER', res);

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            const postRes = res.post;
            const categories: ICategory[] = yield select(getCategories);
            // console.log('cassdtttt', categories);
            if (categories && categories.length > 0)
                if (postRes.cat_main)
                    postRes.category1 = categories.find((cat) => cat.id === postRes.cat_main) || ({} as ICategory);
            if (postRes.category1 && postRes.category1.subCategories && postRes.sub_cat)
                postRes.category2 =
                    postRes.category1.subCategories.find((cat) => cat.id === postRes.sub_cat) || ({} as ICategory);
            if (postRes.category2 && postRes.category2.subCategories && postRes.sub_cat_section)
                postRes.category3 =
                    postRes.category2.subCategories.find((cat) => cat.id === postRes.sub_cat_section) ||
                    ({} as ICategory);
            // res.post = postRes;
            yield put(fetchedOrder(res));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleChargeOrder(action: ReturnType<typeof chargeOrder>) :any{
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/order/charge/${action.payload.bid._id}/${action.payload.gatewayAction}`,
            token,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Order status was updated`), { type: 'success' });
            yield put(fetchUser());
            yield put(fetchOrder(action.payload.bid));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRefundOrder(action: ReturnType<typeof refundOrder>) :any{
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: ApiError = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/order/refund/${action.payload._id}`,
            token,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Order was refunded successfully`), { type: 'success' });

            yield put(fetchOrder(action.payload));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleEditFavorites(action: ReturnType<typeof editFavorites>) :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const curUser: IUser = yield select(getUser);
        if (!token) return yield put(fetchError('User not in state.'));
        // console.log(`/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`);
        const res: any = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/user/editfavorites/${action.payload.fAction}/${action.payload.postId}`,
            token,
        );

        // console.log(res)
        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res));
        } else {
            curUser.favoriteAds = res.favoriteAds;
            // toast("User data updated", { type: toast.TYPE.SUCCESS });
            yield put(fetchSuccess(curUser));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdateUser(action: ReturnType<typeof updateUser>) :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const res: string = yield call(callApi, 'PATCH', API_ENDPOINT, `/user/me`, token, action.payload);

        console.log(res);
        const t = i18next.t.bind(i18next);
        if (!res) {
            // console.log(res)
            yield put(fetchError('Error updating user'));
            toast(t('Error updating user'), { type: toast.TYPE.ERROR });
        } else {
            toast(t('User data updated'), { type: toast.TYPE.SUCCESS });
            yield put(fetchUser());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUploadAvatar(action: ReturnType<typeof uploadAvatar>) :any{
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);

        // const formData = new FormData()
        //     formData.append(
        //         'newAvatar',
        //         this.state.selectedFile!,
        //         this.state.selectedFile!.name
        //     );
        const data = new FormData();
        data.append('file', action.payload);
        // data.append('filename', this.fileName.value);

        const res: string = yield call(callApiUpload, 'POST', API_ENDPOINT, `/user/uploadavatar`, token, data);

        console.log(res);
        const t = i18next.t.bind(i18next);
        if (!res) {
            // console.log(res)
            yield put(fetchError('Error updating avatar'));
            toast(t('Error updating avatar'), { type: toast.TYPE.ERROR });
        } else {
            toast('Avatar updated', { type: toast.TYPE.SUCCESS });
            yield put(fetchUser());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchEmailTemplates() :any{
    try {
        const token = yield select(getToken);
        console.log(token);
        if (!token) return yield put(fetchError('Token not in state.'));

        const emailTemplatesRes: IEmailTemplate[] = yield call(callApi, 'get', API_ENDPOINT, '/email/', token);

        // console.log("fetched email templates", emailTemplatesRes)

        // if (categoriesRes.error) {
        //     console.log(categoriesRes);
        //     yield put(fetchError('Error fetching categories'))
        // } else {

        yield put(onFetchedEmailTemplates(emailTemplatesRes));
        // }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
    yield takeEvery(UserActionTypes.FETCH_USER, handleFetchUser);
}
function* watchFetchRatesRequest() {
    yield takeEvery(UserActionTypes.FETCH_RATES, handleFetchRates);
}
function* watchFetchSentences() {
    yield takeEvery(UserActionTypes.FETCH_SENTENCES, handleFetchSentences);
}
function* watchUpdateUser() {
    yield takeEvery(UserActionTypes.UPDATE_USER, handleUpdateUser);
}
function* watchUploadAvatar() {
    yield takeEvery(UserActionTypes.UPLOAD_AVATAR, handleUploadAvatar);
}
function* watchEditFavorites() {
    yield takeEvery(UserActionTypes.EDIT_FAVORITES, handleEditFavorites);
}
function* watchFetchTransactions() {
    yield takeEvery(UserActionTypes.FETCH_TRANSACTIONS, handleFetchTransactions);
}
function* watchBalanceChange() {
    yield takeEvery(UserActionTypes.BALANCE_CHANGE, handleBalanceChange);
}
function* watchFetchBids() {
    yield takeEvery(UserActionTypes.FETCH_BIDS, handleFetchBids);
}
function* watchFetchOrder() {
    yield takeEvery(UserActionTypes.FETCH_ORDER, handleFetchOrder);
}
function* watchChargeOrder() {
    yield takeEvery(UserActionTypes.CHARGE_ORDER, handleChargeOrder);
}
function* watchRefundOrder() {
    yield takeEvery(UserActionTypes.REFUND_ORDER, handleRefundOrder);
}
function* watchFetchMessages() {
    yield takeEvery(UserActionTypes.FETCH_MESSAGES, handleFetchMessages);
}
function* watchMarkMessagesViewed() {
    yield takeEvery(UserActionTypes.VIEWED_MESSAGE, handleMarkMessagesViewed);
}
function* watchRemoveMessage() {
    yield takeEvery(UserActionTypes.REMOVE_MESSAGE, handleRemoveMessage);
}
function* watchSendMessage() {
    yield takeEvery(UserActionTypes.SEND_MESSAGE, handleSendMessage);
}

function* watchListenMessages() {
    yield takeEvery(UserActionTypes.SOCKET_LISTENMESSAGE, handleListenMessages);
}

function* watchCreateApi() {
    yield takeEvery(UserActionTypes.CREATE_API, handleCreateApi);
}

function* watchRevokeApi() {
    yield takeEvery(UserActionTypes.REVOKE_API, handleRevokeApi);
}

function* watchLeaveFeedback() {
    yield takeEvery(UserActionTypes.LEAVE_FEEDBACK, handleLeaveFeedback);
}

function* watchEditFeedback() {
    yield takeEvery(UserActionTypes.EDIT_FEEDBACK, handleEditFeedback);
}

function* watchFetchSeller() {
    yield takeEvery(UserActionTypes.FETCH_SELLER, handleFetchSeller);
}

function* watchRequestOtp() {
    yield takeEvery(UserActionTypes.REQUEST_OTP, handleRequestOtp);
}

function* watchFetchEmailTemplates() {
    yield takeEvery(UserActionTypes.FETCH_EMAILTEMPLATES, handleFetchEmailTemplates);
}

function* watchValidatePromo() {
    yield takeEvery(UserActionTypes.VALIDATE_PROMO, handleValidatePromo);
}

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* userSaga() {
    yield all([
        fork(watchFetchRequest),
        fork(watchUpdateUser),
        fork(watchUploadAvatar),
        fork(watchEditFavorites),
        fork(watchFetchTransactions),
        fork(watchBalanceChange),
        fork(watchFetchRatesRequest),
        fork(watchFetchBids),
        fork(watchFetchOrder),
        fork(watchChargeOrder),
        fork(watchRefundOrder),
        fork(watchFetchMessages),
        fork(watchMarkMessagesViewed),
        fork(watchSendMessage),
        fork(watchListenMessages),
        fork(watchCreateApi),
        fork(watchRevokeApi),
        fork(watchLeaveFeedback),
        fork(watchEditFeedback),
        fork(watchFetchSeller),
        fork(watchRemoveMessage),
        fork(watchRequestOtp),
        fork(watchFetchEmailTemplates),
        fork(watchValidatePromo),
        fork(watchFetchSentences),
    ]);
}
