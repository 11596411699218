import { IPageTemplate } from '../../types/PageTemplate';

export interface LoginRequest {
    username: string;
    password: string;
}

export enum LoginActionTypes {
    TOKEN_REQUEST = '@@login/TOKEN_REQUEST',
    TOKEN_SOCIALREQUEST = '@@login/TOKEN_SOCIALREQUEST',
    TOKEN_RECEIVED = '@@login/TOKEN_RECEIVED',
    TOKEN_LOGIN = '@@login/TOKEN_LOGIN',
    AUTH_COMPLETED = '@@login/AUTH_COMPLETED',
    FETCH_ERROR = '@@login/FETCH_ERROR',
    REGISTER_NEW = '@@login/REGISTER_NEW',
    REGISTER_SOCIAL = '@@login/REGISTER_SOCIAL',
    REGISTER_COMPLETED = '@@login/REGISTER_COMPLETED',
    LOGOUT = '@@login/LOGOUT',
    LOGOUT_COMPLETED = '@@login/LOGOUT_COMPLETED',
    FETCH_PAGETEMPLATE = '@@pageTemplate/FETCH_PAGETEMPLATE',
    FETCHED_PAGETEMPLATE = '@@pageTemplate/FETCHED_PAGETEMPLATE',
    CONFIRM_EMAIL = 'CONFIRM_EMAIL',

    CHECK_LOCKKEY = 'CHECK_LOCKKEY',
    CHECKED_LOCKKEY = 'CHECKED_LOCKKET',

    CHECK_RECOVER = 'CHECK_RECOVER',
    CHECKED_RECOVER = 'CHECKED_RECOVER',
    CONFIRM_RECOVER = 'CONFIRM_RECOVER',
}

export interface LoginState {
    readonly pageTemplate: IPageTemplate;
    readonly pageTemplateSeller: IPageTemplate;
    readonly pageTemplateBuyer: IPageTemplate;
    readonly loading: boolean;
    readonly token: string;
    readonly loggedIn: boolean;
    readonly errors?: string;
    readonly unlocked: boolean;
    readonly registerCompleted: boolean;
}
