import { Reducer } from 'redux';
import { UserState, UserActionTypes } from './types';
import { IUser } from '../../types/User';
import { ICurrency } from '../../types/Currency';
import { IOrder } from '../../types/Order';
import { Message } from '../../types/Message';
import { toast } from 'react-toastify';
import { getRateValue, mapToObj } from '../../constants';
import { TitleSentence } from '../../types/TitleSentence';
// import i18next from 'i18next';

// Type-safe initialState!
const initialState: UserState = {
    user: {} as IUser,
    rates: {} as ICurrency,
    order: {} as IOrder,
    curRate: navigator.language.substring(0, 2) === 'he' ? 'ILS' : 'USD',
    curRateValue: 1,
    errors: undefined,
    loading: false,
    loggedIn: false,
    registerPromo: undefined,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<UserState> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.FETCH_USER:
        case UserActionTypes.BALANCE_CHANGE:
        case UserActionTypes.FETCH_RATES:
        case UserActionTypes.FETCH_BIDS:
        case UserActionTypes.FETCH_ORDER:
        case UserActionTypes.CHARGE_ORDER:
        case UserActionTypes.REFUND_ORDER:
        case UserActionTypes.FETCH_MESSAGES:
        case UserActionTypes.SEND_MESSAGE:
        case UserActionTypes.CREATE_API:
        case UserActionTypes.REVOKE_API:
        case UserActionTypes.VALIDATE_PROMO:
        case UserActionTypes.FETCH_TRANSACTIONS: {
            return { ...state, registerPromo: undefined, loading: true };
        }

        case UserActionTypes.VALIDATED_PROMO: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, registerPromo: action.payload };
        }

        case UserActionTypes.FETCH_SELLER: {
            return { ...state, loading: true, seller: undefined };
        }

        case UserActionTypes.FETCHED_SELLER: {
            return { ...state, loading: false, seller: Object.assign({}, action.payload) };
        }
        case UserActionTypes.FETCHED_MESSAGES: {
            return { ...state, loading: false };
        }

        case UserActionTypes.CREATED_API: {
            return {
                ...state,
                loading: false,
                apiKeyCreateDate: action.payload.apiKeyCreateDate,
                apiKey: action.payload.apiKey,
            };
        }

        case UserActionTypes.SOCKET_LISTENMESSAGE_RECEIVED: {
            const curUser = Object.assign({}, state.user);
            const newMessage = action.payload as Message;
            if (!curUser.messages) curUser.messages = [];
            curUser.messages.unshift(newMessage);
            toast.info(
                `You have new message from ${newMessage.sender.username === curUser.username ? 'beedIt' : newMessage.sender.username
                }!\nClick to view`,
                {
                    autoClose: 30000,
                    onClick: () => (window.location.href = `/dashboard/messages/${newMessage._id}`),
                    className: 'messageToast',
                },
            );
            console.log(curUser.messages);
            return { ...state, user: curUser };
        }
        case UserActionTypes.FETCH_SUCCESS: {
            // console.log('FETCH_SUCCESS', action.type, action.payload);
            if (action.payload === null) {
                localStorage.removeItem('token');
                return {
                    ...state,
                    loading: false,
                    loggedIn: false,
                    user: {} as IUser,
                    order: {} as IOrder,
                };
            }
            const newUser: IUser = action.payload;
            if (newUser.feesSettingsOverride && newUser.feesSettingsOverride.length > 0) 
                newUser.feesSettings = mapToObj(newUser.feesSettingsOverride);
            return {
                ...state,
                loading: false,
                loggedIn: true,
                user: Object.assign({}, newUser),
                curRate: action.payload.currency ? action.payload.currency : state.curRate,
            };
        }
        case UserActionTypes.USER_UPDATED: {
            const newUser: IUser = action.payload;
            if (newUser.feesSettingsOverride && newUser.feesSettingsOverride.length > 0) 
                newUser.feesSettings = mapToObj(newUser.feesSettingsOverride);
            return {
                ...state,
                loading: false,
                user: Object.assign({}, newUser),
                curRate: action.payload.currency ? action.payload.currency : state.curRate,
            };
        }
        case UserActionTypes.FETCHED_ORDER: {
            return { ...state, loading: false, order: Object.assign({}, action.payload) };
        }
        case UserActionTypes.RATES_FETCHED: {
            // console.log('new rates', action.payload);
            return { ...state, loading: false, rates: Object.assign([], action.payload) };
        }
        case UserActionTypes.RATE_SETTED: {
            const name = action.payload.name as string;
            const rates = action.payload.rates as ICurrency;
            const rateValue = getRateValue(rates, name);
            console.log('set rate', name, rateValue);
            return {
                ...state,
                loading: false,
                curRate: action.payload.name,
                curRateValue: rateValue,
            };
        }
        case UserActionTypes.LOGIN_ERROR: {
            localStorage.removeItem('token');
            return { ...state, loading: false, loggedIn: false };
        }
        case UserActionTypes.FETCH_ERROR: {
            console.log(action.payload);
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.FETCHED_EMAILTEMPLATES: {
            return { ...state, loading: false, emailTemplates: action.payload };
        }
        case UserActionTypes.FETCHED_SENTENCES: {
            const sentences: TitleSentence[] = action.payload;
            console.log({sentences})
            return {...state, loading: false, titleSentences: sentences.filter(val=>val.isEnabled)}
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as userReducer };
