import { ICategory } from '../../types/Category';
import { IPost, ISearchPostRequest, ISetting, IEffect, ColorScheme } from '../../types/Post';
import { IFilter } from '../../types/Filter';
import { Survey, Participator } from '../../types/Survey';
import { Coupon } from '../../types/Coupon';
import { Question } from '../../types/Question';
import { AirportData } from '../../types/Airport';

export enum PostActionTypes {
    FETCH_CATEGORIES = '@@post/FETCH_USER',
    FETCH_POSTS = '@@post/FETCH_POSTS',
    FETCH_USERPOSTS = '@@post/FETCH_USERPOSTS',
    FETCH_POST = '@@post/FETCH_POST',
    REMOVE_POST = '@@post/REMOVE_POST',
    SAVE_POST = '@@post/SAVE_POST',
    PUBLISH_POST = '@@post/PUBLISH_POST',
    UPDATE_POST = '@@post/UPDATE_POST',

    FETCH_BULK_POST = '@@post/FETCH_BULK_POST',
    FETCHED_BULK_POST = '@@post/FETCHED_BULK_POST',
    // UPDATE_USER = '@@post/UPDATE_USER',
    // USER_UPDATED = '@@post/USER_UPDATED',
    // UPLOAD_AVATAR = '@@post/UPLOAD_AVATAR',
    FETCHED_POST = '@@post/FETCHED_POST',
    FETCHED_USERPOST = '@@post/FETCHED_USERPOST',
    FETCHED_POSTS = '@@post/FETCHED_POSTS',
    FETCHED_USERPOSTS = '@@post/FETCHED_USERPOSTS',
    MAKE_BID = '@@post/MAKE_BID',
    BID_SUCCESS = '@@post/BID_SUCCESS',

    DEPOSIT = '@@post/TOGGLE_DEPOSIT',
    DEPOSIT_SUCCESS = '@@post/DEPOSIT_SUCCESS',

    SET_GRIDMODE = '@@post/SET_GRIDMODE',

    FETCH_SUCCESS = '@@post/FETCH_SUCCESS',
    FETCH_ERROR = '@@post/FETCH_ERROR',
    FETCHED_POSTVIEW = 'FETCHED_POSTVIEW',
    FETCH_USERPOST = 'FETCH_USERPOST',
    FETCH_EFFECTS = '@@post/FETCH_EFFECTS',
    FETCHED_EFFECTS = '@@post/FETCHED_EFFECTS',
    FETCH_SETTINGS = '@@post/FETCH_SETTINGS',
    FETCHED_SETTINGS = '@@post/FETCHED_SETTINGS',
    FETCH_FILTERS = '@@post/FETCH_FILTERS',
    FETCHED_FILTERS = '@@post/FETCHED_FILTERS',
    FETCHED_SAVEDPOST = '@@post/FETCHED_SAVEDPOST',
    UPDATED_SEARCHREQUEST = '@@post/UPDATED_SEARCHREQUEST',

    FETCH_SURVEY = '@@survey/FETCH',
    FETCHED_SURVEY = '@@survey/FETCHED',

    FETCH_WINNERS = '@@survey_win/FETCH',
    FETCHED_WINNERS = '@@survey_win/FETCHED',

    VOTE = '@@survey/VOTE',

    VALIDATE_COUPON = '@@coupon/VALIDATE',
    VALIDATED_COUPON = '@@coupon/VALIDATED',

    INITIALIZE_SOCKET = '@@socket/INITIALIZE',
    SOCKET_LISTENPOST = '@@socket/LISTENPOST',
    SOCKET_LISTENNEW = '@@socket/LISTENNEW',
    SOCKET_LISTENNEW_RECEIVED = '@@socket/SOCKET_LISTENNEW_RECEIVED',
    SOCKET_LISTENPOST_RECEIVED = '@@socket/SOCKET_LISTENPOST_RECEIVED',

    ADD_DEMO_POST = '@@demo/ADD_POST',
    BID_DEMO_POST = '@@demo/BID_POST',
    FETCH_DEMO_POSTS = '@@demo/FETCH',
    FETCHED_DEMO_POSTS = '@@demo/FETCHED',

    FETCH_QUESTIONS = '@@qa/FETCH',
    FETCHED_QUESTIONS = '@@qa/FETCHED',
    ASK_QUESTION = '@@qa/ASK',
    EDIT_QUESTION = '@@qa/EDIT',
    REMOVE_QUESTION = '@@qa/REMOVE',

    AIRPORT_SEARCH = '@@airport/SEARCH',
    AIRPORT_FOUND = '@@coupon/FOUND',

    UPDATE_DARKMODE = '@@darkmode/UPDATE',
}

export interface PostState {
    readonly searchRequest: ISearchPostRequest;
    readonly totalPostsCount: number;
    readonly coupon?: Coupon;
    readonly airports?: AirportData[];
    readonly settings: ISetting;
    readonly colorScheme?: ColorScheme;
    readonly filters: IFilter[];
    readonly effects: IEffect[];
    readonly posts: IPost[];
    readonly demoPosts?: IPost[];
    readonly isLast?: boolean;
    readonly userPosts: IPost[];
    readonly bulkPosts?: IPost[];
    readonly currentPost: IPost;
    readonly currentPostView: IPost;
    readonly currentPostQuestions?: Question[];
    readonly currentPostAdd: IPost;
    readonly loading: boolean;
    readonly categories: ICategory[];
    readonly allCategories: ICategory[];
    readonly survey: Survey;
    readonly winners: Participator[];
    readonly errors?: string;
    readonly updaterStarted: boolean;
    readonly uploadingData?: boolean;
    readonly publishingPost?: boolean;
    readonly gridMode: 'grid' | 'list';
    readonly isDarkMode: boolean;
}
