import { socket } from '../../utils/socket';
import { eventChannel } from 'redux-saga';
import { SocketEvents } from '../../constants'

export function createNewPostsChannel() {
    if (socket.hasListeners('listennew')) return;
    socket.emit('listennew')
    const subscribe = (emitter: any) => {
        socket.on(SocketEvents.newPosts, emitter);

        return () => socket.removeListener(SocketEvents.newPosts, emitter);
    };

    return eventChannel(subscribe);
}

export function createListenPostChannel(postId: string) {
    const eventName = SocketEvents.listenPost(postId);
    if (socket.hasListeners(eventName)) return;
    socket.emit('listenpost', postId)
    const subscribe = (emitter: any) => {
        socket.on(eventName, emitter);

        return () => socket.removeListener(eventName, emitter);
    };

    return eventChannel(subscribe);
}