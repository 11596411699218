import { action } from 'typesafe-actions';
import { LoginActionTypes, LoginRequest } from './types';
import { IUser } from '../../types/User';
import { IAuth } from '../../types/Auth';
import { IPageTemplate } from '../../types/PageTemplate';

export const tokenRequest = (auth: IAuth) => action(LoginActionTypes.TOKEN_REQUEST, auth);
export const confirmEmail = (confirmKey: string) => action(LoginActionTypes.CONFIRM_EMAIL, confirmKey);
export const tokenSocialRequest = (type: string, social_token: string) =>
    action(LoginActionTypes.TOKEN_SOCIALREQUEST, { type, social_token });
export const logout = () => action(LoginActionTypes.LOGOUT);
export const logoutCompleted = () => action(LoginActionTypes.LOGOUT_COMPLETED);
export const fetchPageTemplate = (url: string) => action(LoginActionTypes.FETCH_PAGETEMPLATE, url);
export const fetchedPageTemplate = (page: IPageTemplate) => action(LoginActionTypes.FETCHED_PAGETEMPLATE, page);

// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.
export const tokenReceived = (token: string) => action(LoginActionTypes.TOKEN_RECEIVED, token);
export const authCompleted = (authSignature: string) => action(LoginActionTypes.AUTH_COMPLETED, authSignature);

export const registerNew = (newUser: IUser, captchaToken: string) =>
    action(LoginActionTypes.REGISTER_NEW, { newUser, captchaToken });
export const registerSocial = (type: string, data: any) => action(LoginActionTypes.REGISTER_SOCIAL, { type, data });
export const registerCompleted = () => action(LoginActionTypes.REGISTER_COMPLETED);

export const checkLockCode = (code: string) => action(LoginActionTypes.CHECK_LOCKKEY, code);
export const checkedLockCode = (result: boolean) => action(LoginActionTypes.CHECKED_LOCKKEY, result);

export const checkRecover = (username: string) => action(LoginActionTypes.CHECK_RECOVER, username);
export const checkedRecover = (result: boolean) => action(LoginActionTypes.CHECKED_RECOVER, result);
export const confirmRecover = (key: string, newPassword: string) =>
    action(LoginActionTypes.CONFIRM_RECOVER, { key, newPassword });

export const fetchError = (message: string) => action(LoginActionTypes.FETCH_ERROR, message);
