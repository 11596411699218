import moment from 'moment';
import { toast } from 'react-toastify';
import { all, call, fork, put, select, takeEvery, take } from 'redux-saga/effects';

import { ApplicationState } from '..';
import { ApiError, ApiResponse } from '../../types/ApiResponse';
import { ICategory } from '../../types/Category';
import { IEffect, IFilteredPosts, IPost, ISetting } from '../../types/Post';
import { IUser } from '../../types/User';
import callApi from '../../utils/api';
import i18next from 'i18next';

import {
    bidSuccess,
    fetchedEffects,
    fetchedPost,
    fetchedPosts,
    fetchedPostView,
    fetchedSavedPost,
    fetchedSettings,
    fetchedUserPosts,
    fetchError,
    fetchPost,
    fetchPosts,
    fetchSuccess,
    fetchUserPost,
    fetchUserPosts,
    makeBid,
    publishPost,
    removePost,
    savePost,
    updatePost,
    fetchedFilters,
    fetchSurvey,
    fetchedSurvey,
    fetchWinners,
    fetchedWinners,
    voteSurvey,
    validateCoupon,
    validatedCoupon,
    listenNewPostsSocketReceived,
    listenPostSocket,
    listenNewPostsSocket,
    listenPostViewUpdated,
    fetchBulkPost,
    fetchedBulkPost,
    toggleDeposit,
    fetchDemoPosts,
    fetchedDemoPosts,
    fetchQuestions,
    fetchedQuestions,
    askQuestion,
    editQuestion,
    airportSearch,
    airportsData,
} from './actions';
import { PostActionTypes } from './types';
import { IFilter } from '../../types/Filter';
import { Survey, Participator } from '../../types/Survey';
import { Coupon } from '../../types/Coupon';
import { createNewPostsChannel, createListenPostChannel } from './channel';
import { fetchUser, fetchTransactions } from '../user/actions';
import { Question } from '../../types/Question';
import { AirportData } from '../../types/Airport';

// import { UPDATE_JOB_REQ_ACTION } from 'src/constants';
// import { ApiError } from 'src/types/ApiResponse';
// import { IAttrStat } from 'src/types/AttrStat';
// import { IReport } from 'src/types/Report';
// import { IEvent } from 'src/types/Event';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

export const getToken = (state: ApplicationState) => state.login.token;
export const getCategories = (state: ApplicationState) => state.post.categories;
export const getSettings = (state: ApplicationState) => state.post.settings;
export const getUser = (state: ApplicationState) => state.user.user;
export const getUpdaterStarted = (state: ApplicationState) => state.post.updaterStarted;

function* handleListenNewPosts(): any {
    const channel = yield call(createNewPostsChannel);
    if (!channel) {
        console.log('EXIST_newPosts');
        return;
    }
    console.log('INIT_SOCKET_newPosts');
    while (true) {
        const newPosts: IPost[] = yield take(channel);
        // let newPosts: IPost[] = JSON.parse(newPostsData);
        console.log('SOCKET_newPosts', newPosts);

        const categories: ICategory[] = yield select(getCategories);
        // console.log('cassdtttt', categories);
        if (categories && categories.length > 0)
            newPosts.map((post) => {
                if (post.cat_main)
                    post.category1 = categories.find((cat) => cat.id === post.cat_main) || ({} as ICategory);
                if (post.category1 && post.category1.subCategories && post.sub_cat)
                    post.category2 =
                        post.category1.subCategories.find((cat) => cat.id === post.sub_cat) || ({} as ICategory);
                if (post.category2 && post.category2.subCategories && post.sub_cat_section)
                    post.category3 =
                        post.category2.subCategories.find((cat) => cat.id === post.sub_cat_section) ||
                        ({} as ICategory);
                return post;
            });
        yield put(listenNewPostsSocketReceived(newPosts));
    }
}

function* handleListenPost(action: ReturnType<typeof listenPostSocket>): any {
    const channel = yield call(createListenPostChannel, action.payload);
    console.log('INIT_SOCKET_listenPost', action.payload);
    if (!channel) {
        console.log('EXIST_listenPost');
        return;
    }
    while (true) {
        const postRes: IPost = yield take(channel);
        // let postRes: IPost = JSON.parse(updatedPostData);
        console.log('SOCKET_listenPost', postRes);
        const categories: ICategory[] = yield select(getCategories);
        // console.log('cassdtttt', categories);
        if (categories && categories.length > 0)
            if (postRes.cat_main)
                postRes.category1 = categories.find((cat) => cat.id === postRes.cat_main) || ({} as ICategory);
        if (postRes.category1 && postRes.category1.subCategories && postRes.sub_cat)
            postRes.category2 =
                postRes.category1.subCategories.find((cat) => cat.id === postRes.sub_cat) || ({} as ICategory);
        if (postRes.category2 && postRes.category2.subCategories && postRes.sub_cat_section)
            postRes.category3 =
                postRes.category2.subCategories.find((cat) => cat.id === postRes.sub_cat_section) || ({} as ICategory);

        if (postRes.cat_main_2)
            postRes.category2_1 = categories.find((cat) => cat.id === postRes.cat_main_2) || ({} as ICategory);
        if (postRes.category2_1 && postRes.category2_1.subCategories && postRes.sub_cat_2)
            postRes.category2_2 =
                postRes.category2_1.subCategories.find((cat) => cat.id === postRes.sub_cat_2) || ({} as ICategory);
        if (postRes.category2_2 && postRes.category2_2.subCategories && postRes.sub_cat_section_2)
            postRes.category2_3 =
                postRes.category2_2.subCategories.find((cat) => cat.id === postRes.sub_cat_section_2) ||
                ({} as ICategory);

        yield put(listenPostViewUpdated(postRes));
    }
}

function* handleFetchCategories() : any{
    try {
        const categoriesRes: ICategory[] = yield call(callApi, 'get', API_ENDPOINT, '/category/', '');

        const filledCategories = categoriesRes
            .filter((l1) => !l1.parent_id)
            .sort((a, b) => a.position - b.position)
            .map((mainCat) => {
                mainCat.subCategories = categoriesRes
                    .filter((l2) => l2.parent_id === mainCat.id)
                    .sort((a, b) => a.position - b.position)
                    .map((subCat) => {
                        subCat.subCategories = categoriesRes
                            .filter((l3) => l3.parent_id === subCat.id)
                            .sort((a, b) => a.position - b.position);
                        return subCat;
                    });
                return mainCat;
            });
        // console.log('fetched categories', filledCategories);

        yield put(fetchSuccess(filledCategories, categoriesRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchEffects(): any {
    try {
        const effectsRes: IEffect[] = yield call(callApi, 'get', API_ENDPOINT, '/effect/', '');

        // console.log('fetched effectsRes', effectsRes);
        const enabledEffects = effectsRes.filter((ef) => ef.isEnabled);

        yield put(fetchedEffects(enabledEffects));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchFilters(): any {
    try {
        const res: IFilter[] & ApiError = yield call(callApi, 'get', API_ENDPOINT, '/filter/', '');

        if (res.error) {
            const t = i18next.t.bind(i18next);
            console.log(res);
            yield put(fetchError('Error fetching filters'));
            toast(t('Error fetching filters'), { type: toast.TYPE.ERROR });
        } else {
            yield put(fetchedFilters(res));
        }

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchSettings(): any {
    try {
        const settings: ICategory[] = yield select(getCategories);
        if (settings && settings.length > 0) return;

        const res: ApiResponse = yield call(callApi, 'get', API_ENDPOINT, '/setting/', '');

        const settingsRes = res.data as ISetting;
        // const settingsRes = res.data.reduce(
        //     (obj: ISetting, item: { key: string; value: string }) => ((obj[item.key] = item.value), obj),
        //     {},
        // );
        console.log('fetched settingsRes', settingsRes);
        // console.log(object);
        const serverTime = res.time;
        const offset = new Date(serverTime).getTime() - Date.now();

        const prevTime = moment.now();

        moment.now = function () {
            // console.log('new date', offset);
            return offset + Date.now();
        };
        const newTime = moment.now();
        console.log(offset, newTime, prevTime, prevTime - newTime);

        yield put(fetchedSettings(settingsRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchPosts(action: ReturnType<typeof fetchPosts>): any {
    let url = `/post/list${action.payload.searchRequest.onlyFavorites ? 'favorites' : ''}/`;
    const userToken = yield select(getToken);
    const token = userToken ? yield select(getToken) : '';
    // let updaterStarted = yield select(getUpdaterStarted);

    if (action.payload.page && action.payload.countPerPage)
        url += `${action.payload.page}/${action.payload.countPerPage}/`;
    // console.log(url);
    try {
        const filteredPosts: IFilteredPosts = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            url,
            token,
            action.payload.searchRequest,
        );

        const categories: ICategory[] = yield select(getCategories);
        // console.log('cassdtttt', categories);
        if (categories && categories.length > 0)
            filteredPosts.paginatedResults.map((post) => {
                if (post.cat_main)
                    post.category1 = categories.find((cat) => cat.id === post.cat_main) || ({} as ICategory);
                if (post.category1 && post.category1.subCategories && post.sub_cat)
                    post.category2 =
                        post.category1.subCategories.find((cat) => cat.id === post.sub_cat) || ({} as ICategory);
                if (post.category2 && post.category2.subCategories && post.sub_cat_section)
                    post.category3 =
                        post.category2.subCategories.find((cat) => cat.id === post.sub_cat_section) ||
                        ({} as ICategory);
                return post;
            });
        console.log('fetched posts!!', url, filteredPosts);
        // if (!updaterStarted) {
        //     const runner = yield call(
        //         setTimeout,
        //         () => {
        //             fetchPosts(
        //                 action.payload.searchRequest,
        //                 action.payload.page,
        //                 action.payload.countPerPage
        //             );
        //         },
        //         30 * 1000
        //     );
        //     console.log('Runned: ' + runner);
        // }
        yield put(listenNewPostsSocket());
        yield put(fetchedPosts(filteredPosts, action.payload.searchRequest));
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchDemoPosts(action: ReturnType<typeof fetchDemoPosts>): any {
    const url = `/post/demo/`;
    // const userToken = yield select(getToken);
    // const token = userToken ? yield select(getToken) : '';
    // let updaterStarted = yield select(getUpdaterStarted);

    // console.log(url);
    try {
        const filteredPosts: IFilteredPosts = yield call(callApi, 'get', API_ENDPOINT, url, '');

        console.log('fetched posts!!', url, filteredPosts);
        // if (!updaterStarted) {
        //     const runner = yield call(
        //         setTimeout,
        //         () => {
        //             fetchPosts(
        //                 action.payload.searchRequest,
        //                 action.payload.page,
        //                 action.payload.countPerPage
        //             );
        //         },
        //         30 * 1000
        //     );
        //     console.log('Runned: ' + runner);
        // }
        // yield put(listenNewPostsSocket());
        yield put(fetchedDemoPosts(filteredPosts));
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// function* handleFetchFavorites(action: ReturnType<typeof fetchFavorites>) {
//     let url = '/post/listfavorites/';
//     // if (action.payload.searchRequest)
//     if (action.payload.page && action.payload.countPerPage) url += `${action.payload.page}/${action.payload.countPerPage}/`;

//     try {
//         let filteredPosts: IFilteredPosts = yield call(callApi, 'post', API_ENDPOINT, url, '', action.payload.searchRequest)

//         const categories: ICategory[] = yield select(getCategories);
//         // console.log('cassdtttt', categories);
//         if (categories && categories.length > 0)
//         filteredPosts.paginatedResults.map(post=> {
//             if (post.cat_main) post.category1 = categories.find(cat=>cat.id===post.cat_main) || {} as ICategory;
//             if (post.category1 && post.category1.subCategories && post.sub_cat) post.category2 = post.category1.subCategories.find(cat=>cat.id===post.sub_cat) || {} as ICategory;
//             if (post.category2 && post.category2.subCategories && post.sub_cat_section) post.category3 = post.category2.subCategories.find(cat=>cat.id===post.sub_cat_section) || {} as ICategory;
//             return post;
//         })
//         console.log("fetched favorites!!", filteredPosts);

//         yield put(fetchedPosts(filteredPosts, action.payload.searchRequest))

//     } catch (err) {
//         console.log(err)
//         if (err instanceof Error) {
//             yield put(fetchError(err.stack!))
//         } else {
//             yield put(fetchError('An unknown error occured.'))
//         }
//     }
// }

function* handleFetchPost(action: ReturnType<typeof fetchPost>): any {
    try {
        if (action.payload === 'undefined') return;
        const postRes: ApiResponse & IPost = yield call(callApi, 'get', API_ENDPOINT, `/post/${action.payload}`, '');

        console.log('fetched post', postRes);
        const categories: ICategory[] = yield select(getCategories);
        // console.log('cassdtttt', categories);
        if (categories && categories.length > 0)
            if (postRes.cat_main)
                postRes.category1 = categories.find((cat) => cat.id === postRes.cat_main) || ({} as ICategory);
        if (postRes.category1 && postRes.category1.subCategories && postRes.sub_cat)
            postRes.category2 =
                postRes.category1.subCategories.find((cat) => cat.id === postRes.sub_cat) || ({} as ICategory);
        if (postRes.category2 && postRes.category2.subCategories && postRes.sub_cat_section)
            postRes.category3 =
                postRes.category2.subCategories.find((cat) => cat.id === postRes.sub_cat_section) || ({} as ICategory);

        if (postRes.cat_main_2)
            postRes.category2_1 = categories.find((cat) => cat.id === postRes.cat_main_2) || ({} as ICategory);
        if (postRes.category2_1 && postRes.category2_1.subCategories && postRes.sub_cat_2)
            postRes.category2_2 =
                postRes.category2_1.subCategories.find((cat) => cat.id === postRes.sub_cat_2) || ({} as ICategory);
        if (postRes.category2_2 && postRes.category2_2.subCategories && postRes.sub_cat_section_2)
            postRes.category2_3 =
                postRes.category2_2.subCategories.find((cat) => cat.id === postRes.sub_cat_section_2) ||
                ({} as ICategory);

        if (postRes.error) {
            yield put(fetchError(postRes.error));
        } else {
            // const isPostExpired =
            //     !postRes.deadline || (postRes.deadline && moment(postRes.deadline).isSameOrBefore(moment()));
            yield put(listenPostSocket(action.payload));
            yield put(fetchedPostView(postRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUserPost(action: ReturnType<typeof fetchUserPost>) : any{
    try {
        const postRes: ApiResponse & IPost = yield call(callApi, 'get', API_ENDPOINT, `/post/${action.payload}`, '');

        console.log('fetched post', postRes);
        const categories: ICategory[] = yield select(getCategories);
        console.log('cassdtttt', categories);
        if (categories && categories.length > 0)
            if (postRes.cat_main)
                postRes.category1 = categories.find((cat) => cat.id === postRes.cat_main) || ({} as ICategory);
        if (postRes.category1 && postRes.category1.subCategories && postRes.sub_cat)
            postRes.category2 =
                postRes.category1.subCategories.find((cat) => cat.id === postRes.sub_cat) || ({} as ICategory);
        if (postRes.category2 && postRes.category2.subCategories && postRes.sub_cat_section)
            postRes.category3 =
                postRes.category2.subCategories.find((cat) => cat.id === postRes.sub_cat_section) || ({} as ICategory);

        // postRes._id = postRes.id;
        if (postRes.error) {
            yield put(fetchError(postRes.error));
        } else {
            yield put(fetchedSavedPost(postRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchBulkPost(action: ReturnType<typeof fetchBulkPost>): any {
    let url = '/post/bulk';
    if (action.payload) url += '/' + action.payload;
    const token = yield select(getToken);
    try {
        const postsRes: ApiResponse & IPost[] = yield call(callApi, 'get', API_ENDPOINT, url, token);

        // console.log('fetched bulk posts', postsRes);

        if (postsRes.error) {
            yield put(fetchError(postsRes.error));
        } else {
            const categories: ICategory[] = yield select(getCategories);
            console.log('cassdtttt', categories);
            if (categories && categories.length > 0)
                postsRes.map((post) => {
                    if (post.cat_main)
                        post.category1 = categories.find((cat) => cat.id === post.cat_main) || ({} as ICategory);
                    if (post.category1 && post.category1.subCategories && post.sub_cat)
                        post.category2 =
                            post.category1.subCategories.find((cat) => cat.id === post.sub_cat) || ({} as ICategory);
                    if (post.category2 && post.category2.subCategories && post.sub_cat_section)
                        post.category3 =
                            post.category2.subCategories.find((cat) => cat.id === post.sub_cat_section) ||
                            ({} as ICategory);
                    return post;
                });
            // console.log('BBBBBBB', postsRes);
            yield put(fetchedBulkPost(postsRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchUserPosts(action: ReturnType<typeof fetchUserPosts>): any {
    let url = '/post/my';
    if (action.payload) url += '/' + action.payload;
    const token = yield select(getToken);
    try {
        const postsRes: ApiResponse & IPost[] = yield call(callApi, 'get', API_ENDPOINT, url, token);

        console.log('fetched user posts', postsRes);

        if (postsRes.error) {
            yield put(fetchError(postsRes.error));
        } else {
            const categories: ICategory[] = yield select(getCategories);
            console.log('cassdtttt', categories);
            if (categories && categories.length > 0)
                postsRes.map((post) => {
                    if (post.cat_main)
                        post.category1 = categories.find((cat) => cat.id === post.cat_main) || ({} as ICategory);
                    if (post.category1 && post.category1.subCategories && post.sub_cat)
                        post.category2 =
                            post.category1.subCategories.find((cat) => cat.id === post.sub_cat) || ({} as ICategory);
                    if (post.category2 && post.category2.subCategories && post.sub_cat_section)
                        post.category3 =
                            post.category2.subCategories.find((cat) => cat.id === post.sub_cat_section) ||
                            ({} as ICategory);
                    return post;
                });
            yield put(fetchedUserPosts(postsRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleSavePost(action: ReturnType<typeof savePost>): any {
    try {
        const token = yield select(getToken);

        // To call async functions, use redux-saga's `call()`.
        console.log('saving', token, action.payload);
        const res: any = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/post/add/${action.payload.isDraft ? 'draft' : ''}`,
            token,
            action.payload.post,
        );

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            // toast(
            //     action.payload.is_freeBee ? 'FreeBee post successfully published' : 'Post saved but not published yet',
            //     { type: toast.TYPE.SUCCESS },
            // );
            const curPost = action.payload.post;
            if (curPost.is_bulk) curPost.bulk_group_id = res.result;
            else curPost.id = res.result;
            console.log(curPost, res);
            yield put(fetchedSavedPost(curPost));
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handlePublishPost(action: ReturnType<typeof publishPost>): any {
    try {
        const token = yield select(getToken);
        // console.log('publish', action.payload);
        // To call async functions, use redux-saga's `call()`.
        const curPost = action.payload.post;
        const type = action.payload.type;
        const postId = curPost.bulk_group_id ? curPost.bulk_group_id : curPost.id || curPost._id;
        console.log('publish', postId);
        const res: any = yield call(callApi, 'post', API_ENDPOINT, `/post/${type}/${postId}`, token, {
            frame_color: curPost.frame_color,
            ad_effect: curPost.ad_effect,
            is_bulk: curPost.is_bulk,
            self_start: curPost.self_start,
            self_start_time: curPost.self_start_time,
            freeAdsToUse: curPost.freeAdsToUse,
            fee_additional_type: curPost.fee_additional_type,
            // freeAdsToUse_1: curPost.freeAdsToUse_1,
            // freeAdsToUse_2: curPost.freeAdsToUse_2,
            // freeAdsToUse_3: curPost.freeAdsToUse_3,
            bulkPosts: curPost.bulkPosts,
            seller_terms: curPost.seller_terms,
            bulk_count: curPost.is_bulk ? curPost.bulkPosts.length : 0,
            max_price: curPost.max_price,
        });

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            if (type === 'payment') {
                toast(t('Post payed successfully'), { type: toast.TYPE.SUCCESS });
                yield put(fetchUser());
            } else if (type === 'publish') {
                toast(t('Post published successfully'), { type: toast.TYPE.SUCCESS });
                window.location.href = `/dashboard/${curPost.is_bulk ? 'bulk' : ''}postlist`;
            } else if (type === 'selfStart') {
                toast(t('Post self start has set'), { type: toast.TYPE.SUCCESS });
                window.location.href = `/dashboard/${curPost.is_bulk ? 'bulk' : ''}postlist`;
            }
            // const curPost = action.payload;
            // curPost.id = res.result;
            if (type !== 'selfStart') {
                curPost.is_payed = true;
                curPost.is_published = type === 'publish' ? true : false;
            }

            yield put(fetchedPost(Object.assign({}, curPost)));
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRemovePost(action: ReturnType<typeof removePost>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'delete',
            API_ENDPOINT,
            `/post/${action.payload.id}`,
            token,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError(res.error));
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
        } else {
            const t = i18next.t.bind(i18next);
            toast(t('Post removed'), { type: toast.TYPE.SUCCESS });
            if (action.payload.from === 'postForm') window.location.href = `/dashboard/postlist`;
            else yield put(fetchUserPosts());

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleDeposit(action: ReturnType<typeof toggleDeposit>): any {
    try {
        const token = yield select(getToken);
        // console.log(token);
        const type = action.payload.type;

        const user: IUser = yield select(getUser);
        // console.log(user)
        // To call async functions, use redux-saga's `call()`.
        const res: IPost & ApiError = yield call(callApi, 'post', API_ENDPOINT, `/post/deposit`, token, {
            post_id: action.payload.post._id,
            type: type,
        });

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            // yield put(fetchError(res.error));

            // if (action.payload.pageFrom && action.payload.pageFrom === 'postview') {
            //     console.log(bid.post_id)
            //     yield fetchPost(bid.post_id);
            // }

            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            if (type === 'deposit')
                toast(t(`Your deposit has been accepted. You can make bid now`), {
                    type: toast.TYPE.SUCCESS,
                });
            else toast(t('Deposit has been successfully refunded'), { type: toast.TYPE.SUCCESS });
            // yield put(bidSuccess(res))
            // console.log(bid.post_id);
            // window.location.reload();
            // if (action.payload.pageFrom && action.payload.pageFrom === 'postview')
            //     yield fetchPost(bid.post_id);
            yield put(fetchUser());
            // user.balance -= action.payload.amount;

            yield put(fetchTransactions());

            // yield put(fetchedPost(res))
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleMakeBid(action: ReturnType<typeof makeBid>) : any{
    try {
        const token = yield select(getToken);
        // console.log(token);
        const bid = action.payload.bid;

        const user: IUser = yield select(getUser);
        // console.log(user)
        // To call async functions, use redux-saga's `call()`.
        const res: IPost & ApiError = yield call(callApi, 'post', API_ENDPOINT, `/post/bid`, token, bid);

        if (res.error) {
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
            // yield put(fetchError(res.error));

            // if (action.payload.pageFrom && action.payload.pageFrom === 'postview') {
            //     console.log(bid.post_id)
            //     yield fetchPost(bid.post_id);
            // }

            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            if (user && res.last_bid.status !== 'winner')
                toast(`${user.username} ${t('is about to Win if no other bid')}`, {
                    type: toast.TYPE.SUCCESS,
                });
            else if (res.last_bid.status === 'winner')
                toast(`${t('Post won by')} ${user.username}`, {
                    type: toast.TYPE.SUCCESS,
                });
            else toast(t('Bid accepted'), { type: toast.TYPE.SUCCESS });
            // yield put(bidSuccess(res))
            console.log(bid.post_id);
            // window.location.reload();
            // if (action.payload.pageFrom && action.payload.pageFrom === 'postview')
            //     yield fetchPost(bid.post_id);
            yield put(bidSuccess(res));

            // yield put(fetchedPost(res))
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleUpdatePost(action: ReturnType<typeof updatePost>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        console.log(token);
        const url = `/post/${action.payload.post.id || action.payload.post._id}${
            action.payload.isDraft ? '/draft' : ''
        }`;
        // console.log(url);
        const res: ApiResponse & IPost = yield call(callApi, 'PATCH', API_ENDPOINT, url, token, action.payload.post);

        console.log(res);
        const t = i18next.t.bind(i18next);
        if (res.error) {
            console.log('Error updating post', res);
            yield put(fetchError(res.error));
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
        } else {
            // toast(t('Post data updated'), { type: toast.TYPE.SUCCESS });
            yield put(fetchedSavedPost(res));

            // yield put(newGameTime(res))
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchQuestions(action: ReturnType<typeof fetchQuestions>): any {
    try {
        const token: string = yield select(getToken) ?? '';
        console.log('qtoken', token);
        // if (!token) return yield put(fetchError('Token not in state.'));
        const res: ApiResponse & Question[] = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/post/question/${action.payload}`,
            token,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            yield put(fetchedQuestions(res));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAskQuestion(action: ReturnType<typeof askQuestion>): any {
    try {
        console.log('ask', action.payload);
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/post/question/${action.payload.post_id}`,
            token,
            action.payload,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Question asked`), { type: 'success' });

            if (action.payload.post_id) yield put(fetchQuestions(action.payload.post_id));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleEditQuestion(action: ReturnType<typeof editQuestion>): any {
    try {
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));
        const res: ApiError = yield call(
            callApi,
            'PATCH',
            API_ENDPOINT,
            `/post/question/${action.payload.id}`,
            token,
            action.payload,
        );
        const t = i18next.t.bind(i18next);
        if (res.error) {
            toast(t(res.error), { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            toast(t(`Question answer saved`), { type: 'success' });

            if (action.payload.post_id) yield put(fetchQuestions(action.payload.post_id));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchSurvey(action: ReturnType<typeof fetchSurvey>): any {
    try {
        // const token: string = yield select(getToken);
        // if (!token) return yield put(fetchError('Token not in state.'));
        // action.payload.week = 18;
        const surveyRes: ApiResponse & Survey = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/survey/view/${action.payload.year}/${action.payload.week}`,
            '',
        );

        console.log('fetched survey', action.payload, surveyRes);

        // postRes._id = postRes.id;
        if (surveyRes.error) {
            console.log('surveyError', surveyRes.error);
            // yield put(fetchedSurvey({} as Survey));
            yield put(fetchError(surveyRes.error));
        } else {
            const categories: ICategory[] = yield select(getCategories);
            console.log('cassdtttt', categories);
            if (categories && categories.length > 0)
                surveyRes.involved.map((inv) => {
                    inv.category = categories.find((cat) => cat.id === inv.categoryId)!;
                    return inv;
                });
            yield put(fetchedSurvey(surveyRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleFetchWinners(action: ReturnType<typeof fetchWinners>): any {
    try {
        const winnersRes: ApiResponse & Participator[] = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/survey/winners/${action.payload.year}/${action.payload.week}`,
            '',
        );

        console.log('fetched winners', winnersRes);

        // postRes._id = postRes.id;
        if (winnersRes.error) {
            console.log('surveyError', winnersRes.error);
            yield put(fetchError(winnersRes.error));
            // yield put(fetchedWinners([] as Participator[]));
        } else {
            yield put(fetchedWinners(winnersRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleValidateCoupon(action: ReturnType<typeof validateCoupon>): any {
    try {
        const couponRes: ApiError & Coupon = yield call(callApi, 'get', API_ENDPOINT, `/coupon/${action.payload}`, '');

        console.log('validated coupon', couponRes);

        // postRes._id = postRes.id;
        if (couponRes.error) {
            console.log('Error validating coupon', couponRes.error);
            yield put(fetchError(couponRes.error));
        } else {
            yield put(validatedCoupon(couponRes));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleAirportSearch(action: ReturnType<typeof airportSearch>) {
    try {
        const airports: ApiError & AirportData[] = yield call(
            callApi,
            'get',
            API_ENDPOINT,
            `/category/airport/${action.payload}/full`,
            '',
        );

        console.log('airport found', airports);

        // postRes._id = postRes.id;
        if (airports.error) {
            console.log('Error airport search', airports.error);
            yield put(fetchError(airports.error));
        } else {
            yield put(airportsData(airports));
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleVoteSurvey(action: ReturnType<typeof voteSurvey>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const token: string = yield select(getToken);
        if (!token) return yield put(fetchError('Token not in state.'));

        console.log('votes', action.payload.votes);

        const res: ApiResponse & ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/survey/vote/${action.payload.surveyId}`,
            token,
            action.payload.votes,
        );

        console.log(res);
        if (res.error) {
            console.log(res);
            yield put(fetchError('Error making vote'));
            const t = i18next.t.bind(i18next);
            toast(t(res.error), { type: toast.TYPE.ERROR });
        } else {
            const t = i18next.t.bind(i18next);

            toast(t('Vote saved'), { type: toast.TYPE.SUCCESS });
            window.location.reload();

            // yield put(newGameTime(res))
        }
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

// function* handleUpdateUser(action: ReturnType<typeof updateUser>) {
//     try {
//         // To call async functions, use redux-saga's `call()`.
//         const token: string = yield select(getToken);
//         if (!token) return yield put(fetchError('Token not in state.'))
//         console.log(token);
//         const res: string = yield call(callApi, 'PATCH', API_ENDPOINT, `/user/me`, token, action.payload)

//         console.log(res)
//         if (!res) {
//             // console.log(res)
//             yield put(fetchError('Error updating user'))
//             toast("Error updating user", { type: toast.TYPE.ERROR });
//         } else {
//             toast("User data updated", { type: toast.TYPE.SUCCESS });
//             yield put(fetchUser())

//             // yield put(newGameTime(res))
//         }
//     } catch (err) {
//         if (err instanceof Error) {
//             yield put(fetchError(err.stack!))
//         } else {
//             yield put(fetchError('An unknown error occured.'))
//         }
//     }
// }

// function* handleUploadAvatar(action: ReturnType<typeof uploadAvatar>) {
//     try {
//         // To call async functions, use redux-saga's `call()`.
//         const token: string = yield select(getToken);
//         if (!token) return yield put(fetchError('Token not in state.'))
//         console.log(token);

//         // const formData = new FormData()
//         //     formData.append(
//         //         'newAvatar',
//         //         this.state.selectedFile!,
//         //         this.state.selectedFile!.name
//         //     );
//         const data = new FormData();
//         data.append('file', action.payload);
//         // data.append('filename', this.fileName.value);

//         const res: string = yield call(callApiUpload, 'POST', API_ENDPOINT, `/user/uploadavatar`, token, data)

//         console.log(res)
//         if (!res) {
//             // console.log(res)
//             yield put(fetchError('Error updating avatar'))
//             toast("Error updating avatar", { type: toast.TYPE.ERROR });
//         } else {
//             toast("Avatar updated", { type: toast.TYPE.SUCCESS });
//             yield put(fetchUser())

//             // yield put(newGameTime(res))
//         }
//     } catch (err) {
//         if (err instanceof Error) {
//             yield put(fetchError(err.stack!))
//         } else {
//             yield put(fetchError('An unknown error occured.'))
//         }
//     }
// }

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchCategoriesRequest() {
    yield takeEvery(PostActionTypes.FETCH_CATEGORIES, handleFetchCategories);
}
function* watchFetchPostsRequest() {
    yield takeEvery(PostActionTypes.FETCH_POSTS, handleFetchPosts);
}
// function* watchFetchFavoritesRequest() { yield takeEvery(PostActionTypes.FETCH_FAVORITES, handleFetchFavorites) }
function* watchFetchPostRequest() {
    yield takeEvery(PostActionTypes.FETCH_POST, handleFetchPost);
}
function* watchFetchUserPostRequest() {
    yield takeEvery(PostActionTypes.FETCH_USERPOST, handleFetchUserPost);
}
function* watchFetchUserPostsRequest() {
    yield takeEvery(PostActionTypes.FETCH_USERPOSTS, handleFetchUserPosts);
}
function* watchSavePostRequest() {
    yield takeEvery(PostActionTypes.SAVE_POST, handleSavePost);
}
function* watchUpdatePostRequest() {
    yield takeEvery(PostActionTypes.UPDATE_POST, handleUpdatePost);
}
function* watchPublishPostRequest() {
    yield takeEvery(PostActionTypes.PUBLISH_POST, handlePublishPost);
}
function* watchMakeBidRequest() {
    yield takeEvery(PostActionTypes.MAKE_BID, handleMakeBid);
}
function* watchRemovePostRequest() {
    yield takeEvery(PostActionTypes.REMOVE_POST, handleRemovePost);
}
function* watchFetchEffectsRequest() {
    yield takeEvery(PostActionTypes.FETCH_EFFECTS, handleFetchEffects);
}
function* watchFetchSettingsRequest() {
    yield takeEvery(PostActionTypes.FETCH_SETTINGS, handleFetchSettings);
}
function* watchFetchFiltersRequest() {
    yield takeEvery(PostActionTypes.FETCH_FILTERS, handleFetchFilters);
}
function* watchFetchFetchSurvey() {
    yield takeEvery(PostActionTypes.FETCH_SURVEY, handleFetchSurvey);
}
function* watchFetchFetchWinners() {
    yield takeEvery(PostActionTypes.FETCH_WINNERS, handleFetchWinners);
}
function* watchFetchVoteSurvey() {
    yield takeEvery(PostActionTypes.VOTE, handleVoteSurvey);
}

function* watchFetchBulkPost() {
    yield takeEvery(PostActionTypes.FETCH_BULK_POST, handleFetchBulkPost);
}

function* watchValidateCoupon() {
    yield takeEvery(PostActionTypes.VALIDATE_COUPON, handleValidateCoupon);
}

function* watchAirportSearch() {
    yield takeEvery(PostActionTypes.AIRPORT_SEARCH, handleAirportSearch);
}

function* watchListenNewPosts() {
    yield takeEvery(PostActionTypes.SOCKET_LISTENNEW, handleListenNewPosts);
}

function* watchListenPost() {
    yield takeEvery(PostActionTypes.SOCKET_LISTENPOST, handleListenPost);
}

function* watchDeposit() {
    yield takeEvery(PostActionTypes.DEPOSIT, handleDeposit);
}

function* watchFetchDemoPosts() {
    yield takeEvery(PostActionTypes.FETCH_DEMO_POSTS, handleFetchDemoPosts);
}

function* watchFetchQuestions() {
    yield takeEvery(PostActionTypes.FETCH_QUESTIONS, handleFetchQuestions);
}

function* watchEditQuestion() {
    yield takeEvery(PostActionTypes.EDIT_QUESTION, handleEditQuestion);
}

function* watchAskQuestion() {
    yield takeEvery(PostActionTypes.ASK_QUESTION, handleAskQuestion);
}
// function* watchUpdateUser() { yield takeEvery(UserActionTypes.UPDATE_USER, handleUpdateUser) }
// function* watchUploadAvatar() { yield takeEvery(UserActionTypes.UPLOAD_AVATAR, handleUploadAvatar) }

// Export our root saga.
// We can also use `fork()` here to split our saga into multiple watchers.
export function* postSaga() {
    yield all([
        fork(watchFetchCategoriesRequest),
        fork(watchFetchPostsRequest),
        fork(watchFetchUserPostRequest),
        fork(watchSavePostRequest),
        fork(watchPublishPostRequest),
        fork(watchFetchUserPostsRequest),
        fork(watchFetchPostRequest),
        fork(watchMakeBidRequest),
        fork(watchRemovePostRequest),
        fork(watchUpdatePostRequest),
        fork(watchFetchEffectsRequest),
        fork(watchFetchSettingsRequest),
        fork(watchFetchFiltersRequest),
        fork(watchFetchFetchSurvey),
        fork(watchFetchFetchWinners),
        fork(watchFetchVoteSurvey),
        fork(watchValidateCoupon),
        fork(watchAirportSearch),
        fork(watchListenNewPosts),
        fork(watchListenPost),
        fork(watchFetchBulkPost),
        fork(watchDeposit),
        fork(watchFetchDemoPosts),
        fork(watchFetchQuestions),
        fork(watchEditQuestion),
        fork(watchAskQuestion),
    ]);
}
