import { Reducer } from 'redux';
import { LoginActionTypes, LoginState } from './types';
import { IPageTemplate } from '../../types/PageTemplate';

// Type-safe initialState!
const initialState: LoginState = {
    pageTemplate: {} as IPageTemplate,
    pageTemplateSeller: {} as IPageTemplate,
    pageTemplateBuyer: {} as IPageTemplate,
    token: '',
    loggedIn: false,
    errors: undefined,
    loading: true,
    registerCompleted: false,
    unlocked: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<LoginState> = (state = initialState, action) => {
    switch (action.type) {
        case LoginActionTypes.TOKEN_REQUEST:
        case LoginActionTypes.LOGOUT:
        case LoginActionTypes.CHECK_LOCKKEY:
        case LoginActionTypes.CHECK_RECOVER:
        case LoginActionTypes.CONFIRM_RECOVER:
        case LoginActionTypes.FETCH_PAGETEMPLATE: {
            return { ...state, loading: true };
        }
        case LoginActionTypes.CHECKED_LOCKKEY: {
            console.log('chk', action.payload);
            return { ...state, loading: false, unlocked: action.payload };
        }
        case LoginActionTypes.CHECKED_RECOVER: {
            console.log('CHECKED_RECOVER', action.payload);
            return { ...state, loading: false };
        }
        case LoginActionTypes.TOKEN_LOGIN: {
            return { ...state, loading: false, token: '', loggedIn: false };
        }
        case LoginActionTypes.LOGOUT: {
            // localStorage.removeItem('token');
            return { ...state, loading: false };
        }
        case LoginActionTypes.LOGOUT_COMPLETED: {
            console.log(action.type, action.payload);
            return { ...state, loading: false, token: '', loggedIn: false };
        }
        case LoginActionTypes.TOKEN_RECEIVED: {
            console.log(action.type, action.payload);
            localStorage.setItem('token', action.payload);
            return { ...state, loading: false, token: action.payload, loggedIn: true };
        }
        case LoginActionTypes.FETCHED_PAGETEMPLATE: {
            // console.log(action.type, action.payload)
            const curPageTemplate = action.payload as IPageTemplate;
            if (curPageTemplate.url === 'how-it-works-seller')
                return { ...state, loading: false, pageTemplateSeller: action.payload };
            if (curPageTemplate.url === 'how-it-works-buyer')
                return { ...state, loading: false, pageTemplateBuyer: action.payload };
            return { ...state, loading: false, pageTemplate: action.payload };
        }
        case LoginActionTypes.AUTH_COMPLETED: {
            // console.log(action.type, action.payload)
            return { ...state, loading: false, authSignature: action.payload };
        }
        case LoginActionTypes.REGISTER_NEW: {
            return { ...state, loading: false };
        }
        case LoginActionTypes.REGISTER_COMPLETED: {
            // console.log(action.type, action.payload)
            return { ...state, loading: false, registerCompleted: true };
        }
        case LoginActionTypes.FETCH_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as loginReducer };
