import { combineReducers, Action, AnyAction, Dispatch } from 'redux';
import { LayoutState, layoutReducer } from './layout';
import { all, fork } from 'redux-saga/effects';
import { userSaga } from './user/sagas';
// import { UserState } from './user/types';
import { LoginState } from './login/types';
// import { userReducer } from './user/reducer';
import { loginReducer } from './login/reducer';
import { loginSaga } from './login/sagas';
import { UserState } from './user/types';
import { userReducer } from './user/reducer';
import { PostState } from './post/types';
import { postReducer } from './post/reducer';
import { postSaga } from './post/sagas';
import { storeSaga } from './store/sagas';
import { StoreState } from './store/types';
import { storeReducer } from './store/reducer';

export interface ApplicationState {
    layout: LayoutState;
    user: UserState;
    login: LoginState;
    post: PostState;
    store: StoreState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

export const rootReducer = combineReducers<ApplicationState>({
    layout: layoutReducer,
    user: userReducer,
    login: loginReducer,
    post: postReducer,
    store: storeReducer,
});

// We `fork()` these tasks so they execute in the background.
export function* rootSaga() {
    yield all([fork(loginSaga), fork(userSaga), fork(postSaga), fork(storeSaga)]);
}
