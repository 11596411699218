import { Reducer } from 'redux';
import { StoreState, StoreActionTypes } from './types';
import { IStore, IStoreType } from '../../types/Store';

// Type-safe initialState!
const initialState: StoreState = {
    storeTypes: [] as IStoreType[],
    stores: [] as IStore[],
    userStores: [] as IStore[],
    userStore: {} as IStore,
    currentStore: {} as IStore,
    loading: true,
    errors: undefined,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<StoreState> = (state = initialState, action) => {
    switch (action.type) {
        case StoreActionTypes.FETCH_STORE:
        case StoreActionTypes.FETCH_STORES:
        case StoreActionTypes.FETCH_USERSTORE:
        case StoreActionTypes.FETCH_USERSTORES:
        case StoreActionTypes.FETCH_STORETYPES:
        case StoreActionTypes.SAVE_STORE:
        case StoreActionTypes.UPDATE_STORE: {
            return { ...state, loading: true };
        }
        case StoreActionTypes.FETCH_SUCCESS: {
            // console.log(action.type, action.payload)
            return {
                ...state,
                loading: false,
                currentStore: action.payload,
            };
        }
        case StoreActionTypes.FETCHED_STORE: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, currentStore: action.payload };
        }
        case StoreActionTypes.UPDATED_STORE: {
            // console.log("FFFFF_userpost", action.payload);
            return { ...state, loading: false, currentStore: action.payload };
        }
        case StoreActionTypes.FETCHED_STORES: {
            // console.log("FFFFF", action.payload);
            return { ...state, loading: false, stores: action.payload };
        }

        case StoreActionTypes.FETCHED_USERSTORE: {
            // console.log("FFFFF", action.payload);
            return { ...state, loading: false, userStore: action.payload };
        }

        case StoreActionTypes.FETCHED_USERSTORES: {
            // console.log("FFFFF", action.payload);
            return { ...state, loading: false, userStores: Object.assign([], action.payload) };
        }

        case StoreActionTypes.FETCHED_STORETYPES: {
            // console.log("FFFFF", action.payload);
            return { ...state, loading: false, storeTypes: action.payload };
        }

        case StoreActionTypes.FETCH_ERROR: {
            console.log(action.payload);
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as storeReducer };
