import { IPost } from './Post';

export enum TxType {
    BID_POST = 'bid_post',
    DEPOSIT_REFUND = 'deposit_refund',
    FINAL_COMMISSION = 'final_commission',
    DEPOSIT = 'deposit',
    ADD_POST = 'add_post',
    STORE_MEMBERSHIP = 'store_membership',
    ORDER_CHARGE = 'order_charge',
    ORDER_PAYMENT = 'order_payment',
    ORDER_REFUND = 'order_refund',
    GATEWAY_BUYER_COMMISSION = 'gateway_buyer_commission',
    GATEWAY_SELLER_COMMISSION = 'gateway_seller_commission',
    TOPUP = 'topup',
    WITHDRAW = 'withdraw',
    ADMIN_REFUND = 'admin_refund',
}

export enum TxStatus {
    FINISHED = 'finished',
    PENDING = 'pending',
    CANCELLED = 'cancelled',
}

export interface ITransaction {
    id: string;
    tx_id: string;
    source: string;
    status: TxStatus;
    type: TxType;
    amountChange: number;
    itemType?: 'beeditup' | 'freebee';
    is_gateway: boolean;
    owner: string;
    post?: IPost;
    item?: string;
    purchasedOn: Date;
    createdAt: Date;
    updatedAt: Date;
}
