import { socket } from '../../utils/socket';
import { eventChannel } from 'redux-saga';
import { SocketEvents } from '../../constants'

export function createListenMessagesChannel(userId: string, token: string) {
    const eventName = SocketEvents.listenMessages(userId);
    if (socket.hasListeners(eventName)) return;
    socket.emit('listenmessages', token)
    const subscribe = (emitter: any) => {
        socket.on(eventName, emitter);

        return () => socket.removeListener(eventName, emitter);
    };

    return eventChannel(subscribe);
}
