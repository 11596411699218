import { action } from 'typesafe-actions';
import { PostActionTypes } from './types';
import { ICategory } from '../../types/Category';
import { IPost, IBid, IFilteredPosts, ISearchPostRequest, ISetting, IEffect } from '../../types/Post';
import { IFilter } from '../../types/Filter';
import { Survey, Participator, Vote } from '../../types/Survey';
import { Coupon } from '../../types/Coupon';
import { Question } from '../../types/Question';
import { AirportData } from '../../types/Airport';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
export const fetchCategories = () => action(PostActionTypes.FETCH_CATEGORIES);
export const fetchDemoPosts = () => action(PostActionTypes.FETCH_DEMO_POSTS);
export const fetchedDemoPosts = (posts: IFilteredPosts) => action(PostActionTypes.FETCHED_DEMO_POSTS, posts);
export const fetchPost = (id: string) => action(PostActionTypes.FETCH_POST, id);
export const fetchBulkPost = (group_id: string) => action(PostActionTypes.FETCH_BULK_POST, group_id);
export const fetchedBulkPost = (bulkPosts: IPost[]) => action(PostActionTypes.FETCHED_BULK_POST, bulkPosts);
export const fetchUserPost = (id: string) => action(PostActionTypes.FETCH_USERPOST, id);
export const removePost = (id: string, from = 'postForm') => action(PostActionTypes.REMOVE_POST, { id, from });
export const fetchPosts = (searchRequest: ISearchPostRequest, page?: number, countPerPage?: number) =>
    action(PostActionTypes.FETCH_POSTS, { searchRequest, page, countPerPage });
// export const fetchFavorites = (searchRequest: ISearchPostRequest, page?: number, countPerPage?: number) => action(PostActionTypes.FETCH_FAVORITES, {searchRequest, page, countPerPage})
export const fetchUserPosts = (search?: string) => action(PostActionTypes.FETCH_USERPOSTS, search);
export const savePost = (post: IPost, isDraft?: boolean) => action(PostActionTypes.SAVE_POST, { post, isDraft });
export const updatePost = (post: IPost, isDraft?: boolean) => action(PostActionTypes.UPDATE_POST, { post, isDraft });
export const publishPost = (post: IPost, type: 'payment' | 'publish' | 'selfStart') =>
    action(PostActionTypes.PUBLISH_POST, { post, type });

export const updateDarkMode = (status: boolean) => action(PostActionTypes.UPDATE_DARKMODE, status);

export const validateCoupon = (code: string) => action(PostActionTypes.VALIDATE_COUPON, code);
export const validatedCoupon = (coupon: Coupon) => action(PostActionTypes.VALIDATED_COUPON, coupon);

export const airportSearch = (city: string) => action(PostActionTypes.AIRPORT_SEARCH, city);
export const airportsData = (airports: AirportData[]) => action(PostActionTypes.AIRPORT_FOUND, airports);

export const fetchSettings = () => action(PostActionTypes.FETCH_SETTINGS);
export const fetchedSettings = (settings: ISetting) => action(PostActionTypes.FETCHED_SETTINGS, settings);

export const fetchFilters = () => action(PostActionTypes.FETCH_FILTERS);
export const fetchedFilters = (filters: IFilter[]) => action(PostActionTypes.FETCHED_FILTERS, filters);

export const fetchEffects = () => action(PostActionTypes.FETCH_EFFECTS);
export const fetchedEffects = (effects: IEffect[]) => action(PostActionTypes.FETCHED_EFFECTS, effects);

export const fetchSurvey = (week: number, year: number) => action(PostActionTypes.FETCH_SURVEY, { week, year });
export const fetchedSurvey = (survey: Survey) => action(PostActionTypes.FETCHED_SURVEY, survey);

export const fetchWinners = (week: number, year: number) => action(PostActionTypes.FETCH_WINNERS, { week, year });
export const fetchedWinners = (winners: Participator[]) => action(PostActionTypes.FETCHED_WINNERS, winners);

export const voteSurvey = (surveyId: string, votes: Vote[]) => action(PostActionTypes.VOTE, { surveyId, votes });

export const makeBid = (bid: IBid, pageFrom?: string) => action(PostActionTypes.MAKE_BID, { bid, pageFrom });
export const bidSuccess = (post: IPost) => action(PostActionTypes.BID_SUCCESS, post);
// export const makeBid = (bid: IBid) => action(PostActionTypes.MAKE_BID, bid);
// export const updateUser = (user: IUser) => action(UserActionTypes.UPDATE_USER, user)
// export const uploadAvatar = (image: File) => action(UserActionTypes.UPLOAD_AVATAR, image)

export const fetchedPost = (post: IPost) => action(PostActionTypes.FETCHED_POST, post);
export const fetchedSavedPost = (post: IPost) => action(PostActionTypes.FETCHED_SAVEDPOST, post);
export const fetchedPostView = (post: IPost) => action(PostActionTypes.FETCHED_POSTVIEW, post);
export const updatedSearchRequest = (searchRequest: ISearchPostRequest) =>
    action(PostActionTypes.UPDATED_SEARCHREQUEST, searchRequest);
export const fetchedPosts = (filteredPosts: IFilteredPosts, searchRequest: ISearchPostRequest) =>
    action(PostActionTypes.FETCHED_POSTS, { filteredPosts, searchRequest });
export const fetchedUserPosts = (posts: IPost[]) => action(PostActionTypes.FETCHED_USERPOSTS, posts);
export const fetchSuccess = (categories: ICategory[], allCategories: ICategory[]) =>
    action(PostActionTypes.FETCH_SUCCESS, {
        categories,
        allCategories,
    });
export const fetchError = (message: string) => action(PostActionTypes.FETCH_ERROR, message);
export const setContainerMode = (mode: 'list' | 'grid') => action(PostActionTypes.SET_GRIDMODE, mode);

export const initializeSocket = () => action(PostActionTypes.INITIALIZE_SOCKET);
export const listenPostSocket = (postId: string) => action(PostActionTypes.SOCKET_LISTENPOST, postId);
export const listenPostViewUpdated = (post: IPost) => action(PostActionTypes.SOCKET_LISTENPOST_RECEIVED, post);
export const listenNewPostsSocket = () => action(PostActionTypes.SOCKET_LISTENNEW);
export const listenNewPostsSocketReceived = (posts: IPost[]) =>
    action(PostActionTypes.SOCKET_LISTENNEW_RECEIVED, posts);

export const toggleDeposit = (post: IPost, type: 'deposit' | 'refund') =>
    action(PostActionTypes.DEPOSIT, { post, type });

export const fetchQuestions = (postId: string) => action(PostActionTypes.FETCH_QUESTIONS, postId);
export const fetchedQuestions = (questions: Question[]) => action(PostActionTypes.FETCHED_QUESTIONS, questions);
export const editQuestion = (question: Question) => action(PostActionTypes.EDIT_QUESTION, question);
export const askQuestion = (question: Question) => action(PostActionTypes.ASK_QUESTION, question);
export const removeQuestion = (question: Question) => action(PostActionTypes.REMOVE_QUESTION, question);

// export const depositSuccess
