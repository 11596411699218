import { GoogleLoginResponse } from 'react-google-login';
import { toast } from 'react-toastify';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ApiError } from '../../types/ApiResponse';
import { IPageTemplate } from '../../types/PageTemplate';
import { IUser } from '../../types/User';
import callApi from '../../utils/api';
import i18next from 'i18next';
import {
    confirmEmail,
    fetchedPageTemplate,
    fetchError,
    fetchPageTemplate,
    logout,
    logoutCompleted,
    registerCompleted,
    registerNew,
    registerSocial,
    tokenReceived,
    tokenRequest,
    tokenSocialRequest,
    checkLockCode,
    checkedLockCode,
    checkRecover,
    checkedRecover,
    confirmRecover,
} from './actions';
import { LoginActionTypes } from './types';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';

function* handleFetchPageTemplate(action: ReturnType<typeof fetchPageTemplate>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        // const login = yield call(callApi, 'post', API_ENDPOINT, `/page/tokenlogin`, '', action.payload)
        console.log('fetching page', action.payload);

        const pageTemplateRes: IPageTemplate = yield call(callApi, 'get', API_ENDPOINT, `/page/${action.payload}`, '');
        // console.log('fetched page template', pageTemplateRes);

        yield put(fetchedPageTemplate(pageTemplateRes));

        // setInterval(() => { put(onFetchedPerks(perksRes)) }, 1000)
    } catch (err) {
        console.log(err);
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleTokenRequest(action: ReturnType<typeof tokenRequest>): any {
    try {
        const token = yield call(callApi, 'post', API_ENDPOINT, `/auth/login`, '', action.payload);

        console.log(token);
        if (token.error) {
            toast(token.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(token.error));
        } else {
            yield put(tokenReceived(token.jwt));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleSocialTokenRequest(action: ReturnType<typeof tokenSocialRequest>): any {
    try {
        const token = yield call(callApi, 'post', API_ENDPOINT, `/auth/social-login`, '', action.payload);

        // console.log(token);
        if (token.error) {
            toast(token.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(token.error));
        } else {
            yield put(tokenReceived(token.jwt));
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleCheckLockCode(action: ReturnType<typeof checkLockCode>): any {
    try {
        const result: ApiError & { valid: boolean } = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/auth/checkLockPage`,
            '',
            { code: action.payload },
        );

        if (result.error) {
            toast(result.error, { type: toast.TYPE.ERROR });
            yield put(checkedLockCode(false));
        } else {
            if (result.valid) {
                yield put(checkedLockCode(true));
                localStorage.setItem('pw', action.payload);
                window.location.reload();
            } else {
                const t = i18next.t.bind(i18next);
                toast(t('Lock code is invalid'), { type: toast.TYPE.ERROR });
                yield put(checkedLockCode(false));
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        }
    }
}

function* handleCheckRecover(action: ReturnType<typeof checkRecover>): any {
    try {
        const result: ApiError & { valid: boolean } = yield call(callApi, 'post', API_ENDPOINT, `/auth/recover`, '', {
            username: action.payload,
        });
        const t = i18next.t.bind(i18next);
        if (result.error) {
            toast(t(result.error), { type: toast.TYPE.INFO });
            yield put(checkedRecover(false));
        } else {
            if (result.valid) {
                yield put(checkedRecover(true));
                toast.success(result.error);
                // window.location.reload(false);
            } else {
                toast(t('Username is invalid'), { type: toast.TYPE.ERROR });
                yield put(checkedRecover(false));
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        }
    }
}

function* handleConfirmRecover(action: ReturnType<typeof confirmRecover>): any {
    try {
        const result: ApiError & { valid: boolean } = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/auth/confirm-recover`,
            '',
            {
                recoverPasswordKey: action.payload.key,
                password: action.payload.newPassword,
            },
        );

        if (result.error) {
            toast(result.error, { type: toast.TYPE.INFO });
            yield put(checkedRecover(false));
        } else {
            const t = i18next.t.bind(i18next);
            if (result.valid) {
                yield put(checkedRecover(true));
                toast.success(t('Password updated, you can login'));
                window.location.pathname = '/login';
            } else {
                toast(t('Something is invalid'), { type: toast.TYPE.ERROR });
                yield put(checkedRecover(false));
            }
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        }
    }
}

function* handleLogout() {
    try {
        console.log('logout!');
        localStorage.clear();
        yield put(logoutCompleted());
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        }
    }
}

function* handleRegisterNew(action: ReturnType<typeof registerNew>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: ApiError = yield call(
            callApi,
            'post',
            API_ENDPOINT,
            `/user/register/${action.payload.captchaToken}`,
            '',
            action.payload.newUser,
        );

        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            toast(t('Register completed. Please click on link in your email to confirm account'), {
                type: toast.TYPE.SUCCESS,
                autoClose: false,
            });
            // yield put(tokenRequest({username: action.payload.newUser.username, password: action.payload.newUser.password} as IAuth))
            yield put(registerCompleted());
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleConfirmEmail(action: ReturnType<typeof confirmEmail>): any {
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: ApiError = yield call(callApi, 'get', API_ENDPOINT, `/auth/confirm/${action.payload}`, '');

        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            const t = i18next.t.bind(i18next);
            toast(t('Your email successfully confirmed :) You can login now'), { type: toast.TYPE.SUCCESS });
            // yield put(tokenRequest({username: action.payload.newUser.username, password: action.payload.newUser.password} as IAuth))
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* handleRegisterSocial(action: ReturnType<typeof registerSocial>): any {
    try {
        const newUser = {} as IUser;
        if (action.payload.type === 'facebook') {
            const userData = action.payload.data as any;
            const names = userData.name.split(' ') as string[];
            newUser.first_name = names[0];
            newUser.last_name = names[1];
            newUser.username = names[0].toLowerCase() + '_' + names[1].toLowerCase() + '_';
            newUser.facebook_id = userData.id;
            newUser.email = userData.email;
            newUser.avatar_url = userData.picture.data.url;
            newUser.role = 'PERSONAL';
        } else if (action.payload.type === 'google') {
            const userData = (action.payload.data as GoogleLoginResponse).getBasicProfile();
            newUser.first_name = userData.getGivenName();
            newUser.last_name = userData.getFamilyName();
            newUser.username = newUser.first_name.toLowerCase() + '_' + newUser.last_name.toLowerCase();
            newUser.google_id = userData.getId();
            newUser.email = userData.getEmail();
            newUser.avatar_url = userData.getImageUrl();
            newUser.role = 'PERSONAL';
        } else yield put(fetchError('Invalid social type'));
        // To call async functions, use redux-saga's `call()`.
        const res = yield call(callApi, 'post', API_ENDPOINT, `/user/register`, '', newUser);

        if (res.error) {
            toast(res.error, { type: toast.TYPE.ERROR });
            yield put(fetchError(res.error));
        } else {
            console.log(res);
            yield put(tokenReceived(res.jwt));
            // yield put(registerCompleted())
        }
    } catch (err) {
        if (err instanceof Error) {
            yield put(fetchError(err.stack!));
        } else {
            yield put(fetchError('An unknown error occured.'));
        }
    }
}

function* watchFetchPageTemplate() {
    yield takeEvery(LoginActionTypes.FETCH_PAGETEMPLATE, handleFetchPageTemplate);
}

function* watchTokenRequest() {
    yield takeEvery(LoginActionTypes.TOKEN_REQUEST, handleTokenRequest);
}

function* watchSocialTokenRequest() {
    yield takeEvery(LoginActionTypes.TOKEN_SOCIALREQUEST, handleSocialTokenRequest);
}

function* watchRegisterNew() {
    yield takeEvery(LoginActionTypes.REGISTER_NEW, handleRegisterNew);
}

function* watchRegisterSocial() {
    yield takeEvery(LoginActionTypes.REGISTER_SOCIAL, handleRegisterSocial);
}

function* watchConfirmEmail() {
    yield takeEvery(LoginActionTypes.CONFIRM_EMAIL, handleConfirmEmail);
}

function* watchLogout() {
    yield takeEvery(LoginActionTypes.LOGOUT, handleLogout);
}

function* watchHandleCheckLockCode() {
    yield takeEvery(LoginActionTypes.CHECK_LOCKKEY, handleCheckLockCode);
}

function* watchHandleCheckRecover() {
    yield takeEvery(LoginActionTypes.CHECK_RECOVER, handleCheckRecover);
}

function* watchHandleConfirmRecover() {
    yield takeEvery(LoginActionTypes.CONFIRM_RECOVER, handleConfirmRecover);
}

export function* loginSaga() {
    yield all([
        fork(watchFetchPageTemplate),
        fork(watchTokenRequest),
        fork(watchSocialTokenRequest),
        fork(watchRegisterNew),
        fork(watchLogout),
        fork(watchRegisterSocial),
        fork(watchConfirmEmail),
        fork(watchHandleCheckLockCode),
        fork(watchHandleCheckRecover),
        fork(watchHandleConfirmRecover),
    ]);
}
